import { ROLE_ACTIONS } from '../constants/valid-role-actions';

// add parameters to the path
export const toPath = (path, ...params) => {
  let matches = [...path.matchAll(/:[a-zA-Z]+/g)];
  matches.forEach((match, index) => {
    path = path.replace(match[0], params[index]);
  });
  return path;
};
/**
 * RulePageBuilderModule routes of the App
 */
export const newRoutes = {
  login: {
    refactored: true,
    path: '/login',
    componentPath: 'src/pages/public/auth/login',
    exact: true,
    private: false,
    redirectOnAuth: true,
    title: 'Login',
  },
  'reset-password': {
    refactored: true,
    path: '/reset-password',
    componentPath: 'src/pages/public/auth/reset-password',
    exact: true,
    private: false,
    redirectOnAuth: true,
    title: 'Reset Password',
  },
  dashboard: {
    refactored: true,
    path: '/',
    componentPath: 'src/pages/private/dashboard/dashboard',
    exact: true,
    private: true,
    title: 'Dashboard',
  },
  reload: {
    path: '/*/reload',
    component: () => null,
    exact: true,
    private: true,
    title: 'Reloading...',
  },
  notAllowed: {
    refactored: true,
    path: '/not-allowed/:action',
    componentPath: 'src/components/not-allowed-401',
    exact: false,
    private: true,
    title: 'Not Allowed',
  },

  /**
   * Notifications
   */
  notifications: {
    refactored: true,
    path: '/notifications',
    componentPath: 'src/pages/private/notifications/notifications-page',
    exact: true,
    private: true,
    cache: true,
    title: 'Notifications',
    action: ROLE_ACTIONS.Getnotifications,
  },
  notificationsSubscription: {
    refactored: true,
    path: '/notifications/subscription',
    componentPath:
      'src/pages/private/notifications/notifications-subscription/notifications-subscription',
    exact: true,
    private: true,
    cache: false,
    title: 'Notifications Subscription',
    action: ROLE_ACTIONS.Getsubscriptions,
  },
  notificationsPermissions: {
    refactored: true,
    path: '/notifications/permissions',
    componentPath:
      'src/pages/private/notifications/notifications-permissions/notifications-permissions',
    exact: true,
    private: true,
    cache: false,
    title: 'Notifications Permission',
    action: ROLE_ACTIONS.Getpermissions,
  },

  /**
   * Statistic Tool Routes
   */
  topGames: {
    refactored: true,
    path: '/top-games',
    componentPath: 'src/pages/private/statistic-tool-management/top-games',
    exact: true,
    private: true,
    cache: true,
    title: 'Top 5 Games',
    //action: ROLE_ACTIONS.GetGames
  },
  topGamesStatistic: {
    refactored: true,
    path: '/top-games-statistic',
    componentPath: 'src/pages/private/statistic-tool-management/top-games-statistic',
    exact: true,
    private: true,
    cache: true,
    title: 'Games Statistic',
    //action: ROLE_ACTIONS.GetGames
  },
  /**
   * User Management Routes
   */
  profile: {
    refactored: true,
    path: '/profile',
    componentPath: 'src/pages/private/user-management/user-profile',
    exact: true,
    private: true,
    title: 'Profile',
    action: ROLE_ACTIONS.Usergetszhersdata,
  },
  profileEdit: {
    refactored: true,
    path: '/profile/edit',
    componentPath: 'src/pages/private/user-management/add-edit-user',
    exact: true,
    data: {
      profileEdit: true,
    },
    private: true,
    title: 'Edit User',
    action: ROLE_ACTIONS.Userupdateszhersdata,
  },
  usersList: {
    refactored: true,
    path: '/users-list',
    componentPath: 'src/pages/private/user-management/users-list',
    exact: true,
    cache: true,
    private: true,
    title: 'Users List',
    action: ROLE_ACTIONS.Getusersofsystem,
  },
  userAdd: {
    refactored: true,
    path: '/add-user',
    componentPath: 'src/pages/private/user-management/add-edit-user',
    exact: true,
    data: {
      userAdd: true,
    },
    private: true,
    title: 'Add User',
    action: ROLE_ACTIONS.Addsusertosystem,
  },
  userEdit: {
    refactored: true,
    path: '/user/:id/edit',
    componentPath: 'src/pages/private/user-management/add-edit-user',
    exact: true,
    data: {
      userEdit: true,
    },
    private: true,
    title: 'Edit User',
    action: ROLE_ACTIONS.UpdateUserdata,
  },
  userPermissions: {
    refactored: true,
    path: '/user/:id/permissions',
    componentPath: 'src/pages/private/user-management/user-permissions/user-permissions',
    exact: true,
    private: true,
    title: 'Modify Permissions',
    action: ROLE_ACTIONS.Getpermissionsofuser,
  },

  /**
   * Game Management Routes
   */
  keywordsList: {
    refactored: true,
    path: '/keywords-list',
    componentPath: 'src/pages/private/game-management/keywords/keywords-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Keywords List',
    action: ROLE_ACTIONS.GetKeywords,
  },
  keyword: {
    refactored: true,
    path: '/keywords/:id',
    componentPath: 'src/pages/private/game-management/keywords/keyword-details',
    exact: true,
    private: true,
    title: 'Keyword Details',
    action: ROLE_ACTIONS.GetKeyword,
  },
  keywordAdd: {
    refactored: true,
    path: '/new-keyword',
    componentPath: 'src/pages/private/game-management/keywords/keyword-add-edit',
    data: {
      isEditMode: false,
    },
    exact: true,
    private: true,
    title: 'Keyword Add',
    action: ROLE_ACTIONS.AddKeyword,
  },
  keywordsImport: {
    refactored: true,
    path: '/import-keywords',
    componentPath: 'src/pages/private/game-management/keywords/keywords-translations-import',
    exact: true,
    private: true,
    title: 'Import Keywords',
    action: ROLE_ACTIONS.Importgamekeywords,
  },
  keywordEdit: {
    refactored: true,
    path: '/keywords/:id/edit',
    componentPath: 'src/pages/private/game-management/keywords/keyword-add-edit',
    data: {
      isEditMode: true,
    },
    exact: true,
    private: true,
    title: 'Keyword Edit',
    action: ROLE_ACTIONS.UpdateKeyword,
  },
  gamesList: {
    refactored: true,
    path: '/games-list',
    componentPath: 'src/pages/private/game-management/games/games-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Games List',
    action: ROLE_ACTIONS.GetGames,
  },
  gameAdd: {
    refactored: true,
    path: '/new-game',
    componentPath: 'src/pages/private/game-management/games/game-add-edit',
    exact: true,
    private: true,
    title: 'Adding New Game',
    action: ROLE_ACTIONS.AddGame,
  },
  game: {
    refactored: true,
    path: '/games/:id',
    componentPath: 'src/pages/private/game-management/games/game-details',
    exact: true,
    private: true,
    title: 'Game Details',
    action: ROLE_ACTIONS.GetGame,
  },
  gameEdit: {
    refactored: true,
    path: '/games/:id/edit',
    componentPath: 'src/pages/private/game-management/games/game-add-edit',
    exact: true,
    data: {
      isEditMode: true,
    },
    private: true,
    title: 'Game Edit',
    action: ROLE_ACTIONS.UpdateGame,
  },
  gameKeywords: {
    refactored: true,
    path: '/games/:id/keywords',
    componentPath: 'src/pages/private/game-management/games/game-keywords',
    exact: true,
    private: true,
    cache: true,
    title: 'Game Keywords',
    action: ROLE_ACTIONS.GetGameKeywords,
  },
  gameAttachments: {
    refactored: true,
    path: '/games/:id/attachments',
    componentPath: 'src/pages/private/game-management/games/game-attachments/game-attachments',
    exact: true,
    private: true,
    title: 'Game Attachments',
    action: ROLE_ACTIONS.FetchFiles,
  },
  gameAssets: {
    refactored: true,
    path: '/games/:id/assets',
    componentPath: 'src/pages/private/game-management/games/game-assets/game-assets',
    exact: true,
    private: true,
    title: 'Game Assets',
    action: ROLE_ACTIONS.FetchFiles,
  },
  gameLibraries: {
    refactored: true,
    path: '/games/:id/libraries',
    componentPath: 'src/pages/private/game-management/games/game-libraries/game-libraries',
    exact: true,
    private: true,
    title: 'Game Libraries',
    action: ROLE_ACTIONS.FetchFiles,
  },
  languages: {
    refactored: true,
    path: '/languages-list',
    componentPath: 'src/pages/private/game-management/languages/languages-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Languages List',
    action: ROLE_ACTIONS.GetLanguages,
  },
  fonts: {
    refactored: true,
    path: '/fonts-list',
    componentPath: 'src/pages/private/game-management/fonts/fonts-list',
    exact: true,
    private: true,
    title: 'Fonts List',
    action: ROLE_ACTIONS.GetFonts,
  },
  fontStyles: {
    refactored: true,
    path: '/font-styles',
    componentPath: 'src/pages/private/game-management/fonts/fonts-styles-edit',
    exact: true,
    private: true,
    title: 'Fonts Stylesheet',
    action: ROLE_ACTIONS.FetchFiles,
  },
  fontDetails: {
    refactored: true,
    path: '/fonts/:id',
    componentPath: 'src/pages/private/game-management/fonts/font-details',
    exact: true,
    private: true,
    title: 'Fonts Details',
    action: ROLE_ACTIONS.GetFont,
  },
  gameKeywordBulkUpdate: {
    refactored: true,
    path: '/games-keys-bulk-update',
    componentPath: 'src/pages/private/game-management/keywords/games-keywords-bulk-update',
    exact: true,
    private: true,
    title: 'Games Keywords Bulk Update',
    action: ROLE_ACTIONS.BulkUpsert,
  },
  libraries: {
    refactored: true,
    path: '/libraries',
    componentPath: 'src/pages/private/game-management/libraries/libraries-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Libraries List',
    action: ROLE_ACTIONS.GetLibraries,
  },
  librariesAdd: {
    refactored: true,
    path: '/libraries/add',
    componentPath: 'src/pages/private/game-management/libraries/library-add',
    exact: true,
    private: true,
    title: 'Add Library',
    action: ROLE_ACTIONS.AddLibraries,
  },

  /**
   * Customer Management Routes
   */
  customers: {
    refactored: true,
    path: '/customers-list',
    componentPath: 'src/pages/private/account-management/customers-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Customers List',
    action: ROLE_ACTIONS.GetAllCustomers,
  },
  customer: {
    refactored: true,
    path: '/customer/:id',
    componentPath: 'src/pages/private/account-management/customer-details/customer-details',
    exact: true,
    private: true,
    cache: true,
    title: 'Customer Details',
    action: ROLE_ACTIONS.GetCustomer,
  },

  addCustomer: {
    refactored: true,
    path: '/add-customer',
    componentPath: 'src/pages/private/account-management/customer-add-edit',
    exact: true,
    private: true,
    cache: true,
    title: 'Add Customer',
    action: ROLE_ACTIONS.CreateNewCustomer,
  },
  editCustomer: {
    refactored: true,
    path: '/customer/:id/edit',
    componentPath: 'src/pages/private/account-management/customer-add-edit',
    exact: true,
    private: true,
    cache: true,
    title: 'Edit Customer',
    action: ROLE_ACTIONS.UpdateCustomer,
  },
  customerHistory: {
    refactored: true,
    path: '/customer-history',
    componentPath: 'src/pages/private/account-management/customer-history/customer-history',
    exact: true,
    private: true,
    title: 'Customer History',
    action: ROLE_ACTIONS.GetCustomersHistory,
  },
  jurisdictions: {
    refactored: true,
    path: '/jurisdictions',
    componentPath: 'src/pages/private/account-management/jurisdictions-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Jurisdictions',
    action: ROLE_ACTIONS.GetJurisdictions,
  },
  accountPayments: {
    refactored: false,
    path: '/account/:id/payments',
    componentPath: 'src/pages/private/account-management/account-payments/payments-list',
    exact: true,
    private: true,
    title: 'Payments List',
    action: ROLE_ACTIONS.ListPayments,
  },
  accountPaymentAdd: {
    refactored: true,
    path: '/account/:id/add-payment',
    componentPath: 'src/pages/private/account-management/account-payments/add-edit-payment',
    exact: true,
    private: true,
    title: 'Add Payment',
    action: ROLE_ACTIONS.AddPayment,
  },
  accountPaymentEdit: {
    refactored: true,
    path: '/account/:id/payment/:paymentID',
    componentPath: 'src/pages/private/account-management/account-payments/add-edit-payment',
    exact: true,
    data: {
      isEditMode: true,
    },
    private: true,
    title: 'Add Payment',
    action: ROLE_ACTIONS.UpdatePayment,
  },
  elasticExport: {
    refactored: true,
    path: '/elastic-export',
    componentPath: 'src/pages/private/account-management/elastic-export',
    exact: true,
    private: true,
    title: 'Elastic Export',
    action: ROLE_ACTIONS.ElasticQuery,
  },
  freeTickets: {
    refactored: true,
    path: '/free-tickets',
    componentPath: 'src/pages/private/account-management/free-tickets-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Free Tickets',
  },
  playedFreeTickets: {
    refactored: true,
    path: '/played-free-tickets',
    componentPath: 'src/pages/private/account-management/played-free-tickets-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Played Free Tickets',
  },
  playedFreeTicketDetails: {
    refactored: true,
    path: '/played-free-tickets/:siteId',
    componentPath: 'src/pages/private/account-management/played-free-tickets-details',
    exact: true,
    private: true,
    cache: true,
    title: 'Played Tickets Details',
  },
  /**
   * Deploy Management Routes
   */
  releasesList: {
    refactored: true,
    path: '/releases',
    componentPath: 'src/pages/private/deploy-management/deploy-releases-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Releases List',
    action: ROLE_ACTIONS.GetReleases,
  },
  releaseDetails: {
    refactored: true,
    path: '/release/:id',
    componentPath: 'src/pages/private/deploy-management/release-details',
    exact: true,
    private: true,
    title: 'Release Details',
    action: ROLE_ACTIONS.GetRelease,
  },
  gamesOfSites: {
    refactored: true,
    path: '/deploy/games-of-sites',
    componentPath: 'src/pages/private/deploy-management/games-of-sites',
    exact: true,
    private: true,
    cache: true,
    title: 'Games of Sites',
    action: ROLE_ACTIONS.ToggleGameSiteStatus,
  },
  sitesOfGame: {
    refactored: true,
    path: '/deploy/sites-of-game',
    componentPath: 'src/pages/private/deploy-management/sites-of-game',
    exact: true,
    private: true,
    cache: true,
    title: 'Sites of Game',
    action: ROLE_ACTIONS.GetEnabledSiteGames,
  },
  versions: {
    refactored: true,
    path: '/deploy/versions',
    componentPath: 'src/pages/private/deploy-management/versions-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Versions',
    action: ROLE_ACTIONS.GetGameProjectVersions,
  },

  /**
   * Rule Management Routes
   */
  rulesEditor: {
    refactored: true,
    path: '/rules/editor',
    componentPath: 'src/pages/private/game-management/rules/rules-editor',
    exact: true,
    private: true,
    title: 'Rules Editor',
    action: ROLE_ACTIONS.FetchFiles,
  },
  rulesOfGames: {
    refactored: true,
    path: '/rules/rules-of-games',
    componentPath: 'src/pages/private/game-management/rules/rules-of-games',
    exact: true,
    private: true,
    title: 'Games Rules',
    action: ROLE_ACTIONS.QueryRules,
  },
  rulesSharedStyles: {
    refactored: true,
    path: '/rules/shared-styles',
    componentPath: 'src/pages/private/game-management/rules/rules-styles-edit',
    exact: true,
    private: true,
    title: 'Rules Shared Styles',
    action: ROLE_ACTIONS.FetchFiles,
  },

  /**
   * Emailing Management Routes
   */
  emailingContacts: {
    refactored: true,
    path: '/emailing/contacts',
    componentPath: 'src/pages/private/emailing-management/contacts-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Contacts List',
    action: ROLE_ACTIONS.GetContacts,
  },
  emailingMails: {
    refactored: true,
    path: '/emailing/emails',
    componentPath: 'src/pages/private/emailing-management/emails-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Emails List',
    action: ROLE_ACTIONS.Getemails,
  },
  emailingNewEmail: {
    refactored: true,
    path: '/emailing/new-email',
    componentPath: 'src/pages/private/emailing-management/email-add-edit',
    exact: true,
    private: true,
    title: 'New Email',
    action: ROLE_ACTIONS.Addemail,
  },
  emailingEditEmail: {
    refactored: true,
    path: '/emailing/emails/:id/edit',
    componentPath: 'src/pages/private/emailing-management/email-add-edit',
    exact: true,
    private: true,
    title: 'Edit Email',
    action: ROLE_ACTIONS.Editemailcontent,
  },
  emailingEmailDetails: {
    refactored: true,
    path: '/emailing/emails/:id',
    componentPath: 'src/pages/private/emailing-management/email-details',
    exact: true,
    private: true,
    title: 'Email Details',
    action: ROLE_ACTIONS.Getemail,
  },

  /**
   * Bet Configs Management Routes
   */
  betConfigsSites: {
    refactored: true,
    path: '/bet-configs/sites',
    componentPath: 'src/pages/private/bet-config-management/sites-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Sites List',
    action: ROLE_ACTIONS.GetSites,
  },
  betConfigsAccounts: {
    refactored: true,
    path: '/bet-configs/accounts',
    componentPath: 'src/pages/private/bet-config-management/accounts-list/accounts-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Accounts List',
    action: ROLE_ACTIONS.GetAccounts,
  },
  betConfigsNew: {
    refactored: true,
    path: '/bet-configs/new-config',
    componentPath: 'src/pages/private/bet-config-management/bet-config-add',
    exact: true,
    private: true,
    cache: true,
    title: 'New Bet Config',
    action: ROLE_ACTIONS.CreateBetConfig,
  },
  betConfigsList: {
    refactored: true,
    path: '/bet-configs/configs',
    componentPath: 'src/pages/private/bet-config-management/bet-configs-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Bet Configs List',
    action: ROLE_ACTIONS.GetBetConfigs,
  },
  betConfigDetails: {
    refactored: true,
    path: '/bet-configs/configs/:id/:env?',
    componentPath: 'src/pages/private/bet-config-management/bet-config-details',
    exact: true,
    private: true,
    cache: true,
    title: 'Bet Config Details',
    action: ROLE_ACTIONS.GetBetConfig,
  },
  betConfigsLimits: {
    refactored: true,
    path: '/bet-configs/limits',
    componentPath: 'src/pages/private/bet-config-management/bet-limits-list',
    exact: true,
    private: true,
    cache: true,
    title: 'Bet Limits Details',
    action: ROLE_ACTIONS.GetBetLimits,
  },

  /**
   * Game Tech Management Routes
   */
  gameTechUsers: {
    refactored: true,
    path: '/game-tech/users',
    componentPath: 'src/pages/private/game-tech-management/gt-users-list',
    exact: true,
    private: true,
    title: 'GT Users List',
    action: ROLE_ACTIONS.Getgametechusers,
  },
  gameTechLocations: {
    refactored: true,
    path: '/game-tech/locations',
    componentPath: 'src/pages/private/game-tech-management/gt-locations-list',
    exact: true,
    private: true,
    cache: true,
    data: {
      locationsList: true,
    },
    title: 'GT Locations',
    action: ROLE_ACTIONS.GetGametechJurisdictions,
  },
  gameTechNewArea: {
    refactored: true,
    path: '/game-tech/new-gametech-area',
    componentPath: 'src/pages/private/game-tech-management/gt-new-area',
    exact: true,
    private: true,
    title: 'New Gametech Area',
    action: ROLE_ACTIONS.GetGametechJurisdictions,
  },
};
