import React from "react";
import Button from "@mui/material/Button";
import {Link} from "react-router-dom";
import {Box, Typography} from "@mui/material";
import bgGif from "../assets/images/not-found.gif"
import PageMeta from "./layout/page-meta";

export default function NotFound404(props) {
    return (
        <>
            <PageMeta title={"Not Found - 404"}/>
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Box sx={{
                    height: "40vh",
                    width: "100%",
                    overflow: "hidden"
                }}>
                    <Box sx={{
                        backgroundImage: `url(${bgGif})`,
                        backgroundSize: 'contain',
                        backgroundPosition: "center -10vh",
                        backgroundRepeat: "no-repeat",
                        height: "150%",
                        width: "100%",
                    }}/>
                </Box>
                <Box sx={{
                    p: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column"
                }}>
                    <Typography variant={"h3"} sx={{mb: .5}}>
                        Looks like you're lost!
                    </Typography>

                    <Typography variant={"body1"} sx={{mb: 2}}>the page you are looking for not found!</Typography>

                    <Link to={"/"}>
                        <Button variant={"outlined"} color={"primary"}>Go to Dashboard</Button>
                    </Link>
                </Box>
            </Box>
        </>
    )
}

