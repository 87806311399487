/**
 * list of valid role actions,
 * this list must update by PermittedCalls which passed in login response
 */
export const ROLE_ACTIONS = {
    UpdateCustomer: "UpdateCustomer",
    CreateNewCustomer: "CreateNewCustomer",
    GetCustomer: "GetCustomer",
    GetAllCustomers: "GetAllCustomers",
    CopyGameLibraries: "CopyGameLibraries",
    UpdateGamesRtp: "UpdateGamesRtp",
    ToggleGameSiteStatus: "ToggleGameSiteStatus",
    GetGametechJurisdictions: "GetGametechJurisdictions",
    AddGametechJurisdiction: "AddGametechJurisdiction",
    UpdateGametechJurisdiction: "UpdateGametechJurisdiction",
    DeleteGametechJurisdiction: "DeleteGametechJurisdiction",
    GameServerLanguagePurge: "GameServerLanguagePurge",
    UpdateGameUIofGames: "UpdateGameUIofGames",
    EnableGameFeaturescreen: "EnableGameFeaturescreen",
    EnableGameIntrovideo: "EnableGameIntrovideo",
    UpdateSiteGameConfig: "SiteGameConfig",
    TerminalArchive: "TerminalArchive",
    Getgametechpermissions: "Getgametechpermissions",
    Updategametechuserpermissions: "Updategametechuserpermissions",
    UpdateGameGameUIType: "UpdateGameGameUIType",
    CurrencySpecialConfig: "CurrencySpecialConfig",
    CurrencySpecialConfigForGame: "CurrencySpecialConfigForGame",
    DeletePassiveBetConfigs: "DeletePassiveBetConfigs",
    SetAccountType: "SetAccountType",
    MoveAccount: "MoveAccount",
    UpdateAccountSalespeople: "UpdateAccountSalespeople",
    UpdateAccountJurisdictions: "UpdateAccountJurisdictions",
    AddJurisdiction: "AddJurisdiction",
    GetSalespeople: "GetSalespeople",
    GetGameProjectVersions: "GetGameProjectVersions",
    AddKeywordsToGames: "AddKeywordsToGames",
    RestructureBetConfigs: "RestructureBetConfigs",
    GetBetLimits: "GetBetLimits",
    EnableGameSite: "EnableGameSite",
    GetEnabledSiteGames: "GetEnabledSiteGames",
    GetGamesOfKeyword: "GetGamesOfKeyword",
    UpdateUserLeadership: "UpdateUserLeadership",
    Updateareaofuser: "Updateareaofuser",
    Updatedefaultareaofuser: "Updatedefaultareaofuser",
    Getgametechusers: "Getgametechusers",
    Getgametechareas: "Getgametechareas",
    Getgamelanguages: "Getgamelanguages",
    EnableProdGameLanguage: "EnableProdGameLanguage",
    EnableGameLanguage: "EnableGameLanguage",
    GetAccounts: "GetAccounts",
    GetDeploymentGames: "GetDeploymentGames",
    GetContacts: "GetContacts",
    DeleteBetConfig: "DeleteBetConfig",
    GetBetConfig: "GetBetConfig",
    GetSites: "GetSites",
    AddAccount: "AddAccount",
    AddCompany: "AddCompany",
    AddContact: "AddContact",
    AddFont: "AddFont",
    AddGame: "AddGame",
    AddKeyword: "AddKeyword",
    AddLanguage: "AddLanguage",
    AddLibraries: "AddLibraries",
    AddPayment: "AddPayment",
    AddProjecttoRelease: "AddProjecttoRelease",
    AddRelease: "AddRelease",
    Addaddress: "Addaddress",
    Addemail: "Addemail",
    Addpermissions: "Addpermissions",
    Addsaurlpermissiontouser: "Addsaurlpermissiontouser",
    Addsusertosystem: "Addsusertosystem",
    ApproveProjectofRelease: "ApproveProjectofRelease",
    BulkUpsert: "BulkUpsert",
    Copyemail: "Copyemail",
    Copygamekeywords: "Copygamekeywords",
    CreateBetConfig: "CreateBetConfig",
    DeleteAccount: "DeleteAccount",
    DeleteCompany: "DeleteCompany",
    DeleteContact: "DeleteContact",
    DeleteFont: "DeleteFont",
    DeleteFontsettingofkeywordfromgame: "DeleteFontsettingofkeywordfromgame",
    DeleteGame: "DeleteGame",
    DeleteKeyword: "DeleteKeyword",
    DeleteKeywordFromGame: "DeleteKeywordFromGame",
    DeleteKeywordLanguageSetting: "DeleteKeywordLanguageSetting",
    DeleteLanguage: "DeleteLanguage",
    DeletePayment: "DeletePayment",
    DeleteProjectfromRelease: "DeleteProjectfromRelease",
    DeleteRelease: "DeleteRelease",
    Deleteaddress: "Deleteaddress",
    DeleteapprovalofProjectofRelease: "DeleteapprovalofProjectofRelease",
    Deleteemail: "Deleteemail",
    Deletegameasset: "Deletegameasset",
    Deletekeywordwithallincludedgames: "Deletekeywordwithallincludedgames",
    Deletelibrary: "Deletelibrary",
    Deletepermissions: "Deletepermissions",
    Deletesaurlpermissionfromuser: "Deletesaurlpermissionfromuser",
    Deletesuserfromsystem: "Deletesuserfromsystem",
    Editemailaddresses: "Editemailaddresses",
    Editemailcontent: "Editemailcontent",
    Exportgamekeywords: "Exportgamekeywords",
    Exportkeywordsindefaultlanguage: "Exportkeywordsindefaultlanguage",
    FetchFiles: "FetchFiles",
    GetAccount: "GetAccount",
    GetBetConfigs: "GetBetConfigs",
    GetCountries: "GetCountries",
    GetCurrencies: "GetCurrencies",
    GetCustomers: "GetCustomers",
    GetCustomersHistory: "GetCustomersHistory",
    GetFont: "GetFont",
    GetFonts: "GetFonts",
    GetGame: "GetGame",
    GetGameConfigs: "GetGameConfigs",
    GetGameKeywords: "GetGameKeywords",
    GetGames: "GetGames",
    GetJurisdictions: "GetJurisdictions",
    GetKeyword: "GetKeyword",
    GetKeywords: "GetKeywords",
    GetLanguage: "GetLanguage",
    GetLanguages: "GetLanguages",
    GetLatestofFiles: "GetLatestofFiles",
    GetLibraries: "GetLibraries",
    GetProjectCommits: "GetProjectCommits",
    GetRelease: "GetRelease",
    GetReleases: "GetReleases",
    GetUserdata: "GetUserdata",
    Getaddress: "Getaddress",
    Getemail: "Getemail",
    Getemails: "Getemails",
    Getnotifications: "Getnotifications",
    Getpermissions: "Getpermissions",
    Getpermissionsofuser: "Getpermissionsofuser",
    Getprojects: "Getprojects",
    Getserverinfos: "Getserverinfos",
    Getsubscriptions: "Getsubscriptions",
    Getusersofsystem: "Getusersofsystem",
    Importgamekeywords: "Importgamekeywords",
    Importkeywordsintranslatedlanguage: "Importkeywordsintranslatedlanguage",
    InsertgameKeywords: "InsertgameKeywords",
    ListCustomerPermissionsOfUser: "ListCustomerPermissionsOfUser",
    ListPayments: "ListPayments",
    Listentonotifications: "Listentonotifications",
    Listentotopic: "Listentotopic",
    Markasread: "Markasread",
    Overwritekeyword: "Overwritekeyword",
    Purgefile: "Purgefile",
    Purgereleaseassets: "Purgereleaseassets",
    QueryRules: "QueryRules",
    Registertonotifications: "Registertonotifications",
    Release: "Release",
    RemoveLibraries: "RemoveLibraries",
    Sendemail: "Sendemail",
    SetDefaultFont: "SetDefaultFont",
    Subscribetonotifications: "Subscribetonotifications",
    Subscribetotopic: "Subscribetotopic",
    Unlistentonotifications: "Unlistentonotifications",
    Unlistentotopic: "Unlistentotopic",
    Unsubscribetonotifications: "Unsubscribetonotifications",
    Unsubscribetotopic: "Unsubscribetotopic",
    UpdateAccount: "UpdateAccount",
    UpdateCompany: "UpdateCompany",
    UpdateContact: "UpdateContact",
    UpdateCustomerPermissionsOfUser: "UpdateCustomerPermissionsOfUser",
    UpdateFont: "UpdateFont",
    UpdateFontsettingofkeywordofgame: "UpdateFontsettingofkeywordofgame",
    UpdateGame: "UpdateGame",
    UpdateGamekeywordsofRelease: "UpdateGamekeywordsofRelease",
    UpdateGamespaylinecount: "UpdateGamespaylinecount",
    UpdateKeyword: "UpdateKeyword",
    UpdateKeywordLanguageSetting: "UpdateKeywordLanguageSetting",
    UpdateLanguage: "UpdateLanguage",
    UpdateLibrary: "UpdateLibrary",
    UpdatePayment: "UpdatePayment",
    UpdateRelease: "UpdateRelease",
    UpdateUserdata: "UpdateUserdata",
    UploadFiles: "UploadFiles",
    UpsertGameConfigs: "UpsertGameConfigs",
    UpsertGameBackendConfigs: "UpsertGameBackendConfigs",
    Usergetszhersdata: "Usergetszhersdata",
    Userupdateszhersdata: "Userupdateszhersdata",
    GetContact: "GetContact",
    GetUsersForRelease: "GetUsersForRelease",
    ElasticQuery: "ElasticQuery",
    FixGameKeywords: "FixGameKeywords",
}
