import {settingsConstants} from "../constants";
import config from "../config.json";
import {createCustomTheme} from "../utils/mui-theme";
import allThemes from "../constants/themes.contants";

function getTheme(darkMode) {
    if (darkMode === void 0)
        darkMode = getDarkModeStatus()
    return createCustomTheme(darkMode ? allThemes.dark : allThemes.default)
}

function getDarkModeStatus() {
    return localStorage.getItem("darkMode") === "true"
}

const initialState = {
    actions_menu_open: false,
    main_sidebar_close: false,
    lastVisitedRoute: {},
    theme: getTheme(),
    darkMode: getDarkModeStatus()
};

export function settings(state = initialState, action) {

    switch (action.type) {
        case settingsConstants.ACTIONS_MENU_OPEN:
            return {
                ...state,
                actions_menu_open: action.payload
            };

        case settingsConstants.MAIN_SIDEBAR_CLOSE:
            localStorage.setItem("MAIN_SIDEBAR_CLOSE", action.payload)
            return {
                ...state,
                main_sidebar_close: action.payload
            };

        case settingsConstants.SET_CURRENT_ROUTE_OBJ:
            if (action.payload.title)
                document.title = `${action.payload.title} - ${config.APP_NAME}`

            return {
                ...state,
                lastVisitedRoute: {path: state.lastVisitedRoute?.path, ...action.payload},
            };

        case settingsConstants.SET_DARK_MODE:
            const darkMode = action.payload
            localStorage.setItem("darkMode", darkMode);

            return {
                ...state,
                darkMode,
                theme: getTheme(darkMode)
            }

        default:
            return state;
    }
}
