import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {FaFileDownload} from "react-icons/all";
import ServiceHistory from "../utils/service-history";
import useInterval from "../hooks/use-interval";
import Icons from "./icons";

const PREFIX = 'FailuresLogDownloadBtn';

const classes = {
    logDownloadBtn: `${PREFIX}-logDownloadBtn`
};

const StyledTooltip = styled(Tooltip)(() => ({
    [`& .${classes.logDownloadBtn}`]: {
        position: "absolute",
        bottom: -12,
        marginLeft: 8,
        zIndex: 999
    }
}));

function FailuresLogDownloadBtn() {

    const [visibility, setVisibility] = useState()

    useInterval(() => {
        setVisibility(ServiceHistory.hasHistory())
    }, 5000)

    const onLogDownloadBtnClick = () => ServiceHistory.downloadHistory()

    if (!visibility) return null

    return (
        <StyledTooltip title={"Download Failures Log"}>
            <IconButton
                className={classes.logDownloadBtn}
                onClick={onLogDownloadBtnClick}>
                <Icons.ErrorDownload/>
            </IconButton>
        </StyledTooltip>
    );
}

FailuresLogDownloadBtn.propTypes = {};

export default FailuresLogDownloadBtn
