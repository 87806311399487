/**
 * Redux actions for the settings of the App
 */
import {settingsConstants} from "../constants";

export const settingsActions = {
    toggleMainMenu,
    setCurrentRouteObj,
    setDarkMode
};

/**
 * action to toggle main menu open status
 * @param status
 * @returns {Function}
 */
function toggleMainMenu(status = true) {
    return dispatch => {
        dispatch({
            type: settingsConstants.MAIN_SIDEBAR_CLOSE,
            payload: status
        })
    }
}

/**
 * action to set current page title on document and content header
 * @param route
 * @returns {Function}
 */
function setCurrentRouteObj(route = {}) {
    return dispatch => {
        dispatch({
            type: settingsConstants.SET_CURRENT_ROUTE_OBJ,
            payload: route
        })
    }
}

function setDarkMode(mode){
    return dispatch => {
        dispatch({
            type: settingsConstants.SET_DARK_MODE,
            payload: mode
        })
    }
}
