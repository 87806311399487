import React from 'react';
import {ToastContainer} from "react-toastify";
import {styled} from "@mui/styles";

const StyledToastContainer = styled(ToastContainer)(({theme}) => ({
    "& .Toastify__toast": {
        borderRadius: theme.shape.borderRadius,
        backdropFilter: "blur(4px)",
        boxShadow: theme.shadows[1],
        zIndex: theme.zIndex.tooltip
    },
    "& .Toastify__toast--success": {
        backgroundColor: theme.palette.background.primaryText,
        color: theme.palette.primary.main,
    },
    "& .Toastify__toast--error": {
        backgroundColor: theme.palette.background.dangerText,
        color: theme.palette.danger.main,
    },
    "& .Toastify__toast--warning": {
        backgroundColor: theme.palette.background.secondaryText,
        color: theme.palette.secondary.main,
    },
    "& .Toastify__toast--info": {
        backgroundColor: theme.palette.background.infoText,
        color: theme.palette.info.main,
    },
}))

export default StyledToastContainer