import {ClientHandler} from "js-service-wrapper";
import BASE_SERVICE_URL from "./get-service-url";

/**
 *
 * @param params
 * @returns {ClientHandler}
 */
function getALlGames(params) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + `/gamelanguage/games/summary`,
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 *
 * @param params
 * @returns {ClientHandler}
 */
function getAllKeywords(params) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + `/gamelanguage/keywords`,
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 *
 * @param id
 * @param params
 * @returns {ClientHandler}
 */
function getKeyword(id, params = {}) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + `/gamelanguage/keywords/${id}`,
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 * promise method to delete a keyword
 * @param id
 * @returns {ClientHandler}
 */
function deleteKeyword(id) {
    return new ClientHandler({
        method: 'delete',
        url: `${BASE_SERVICE_URL}/gamelanguage/keywords/${id}`,
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

/**
 * promise method to delete a keyword with all games
 * @param id
 * @returns {ClientHandler}
 */
function deleteKeywordWithAllGames(id) {
    return new ClientHandler({
        method: 'delete',
        url: `${BASE_SERVICE_URL}/gamelanguage/keywords/${id}/withAllGames`,
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

/**
 * promise method to handle keywords export
 * @param params
 * @returns {ClientHandler}
 */
function exportKeywords(params = {}) {
    return new ClientHandler({
        method: 'get',
        url: `${BASE_SERVICE_URL}/gamelanguage/keywords/export`,
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 * promise method to handle keyword overriding
 * @param data
 */
function overwriteKeyword(data = {}) {
    return new ClientHandler({
        method: 'put',
        url: `${BASE_SERVICE_URL}/gamelanguage/keywords/overwrite`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 * promise method to handle keyword copy
 * @param data
 */
function copyKeywords(data = {}) {
    return new ClientHandler({
        method: 'post',
        url: `${BASE_SERVICE_URL}/gamelanguage/games/keywords/copy`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 * promise method to handle game keyword fix
 * @param data
 */
function fixGameKeywords(data = {}) {
    return new ClientHandler({
        method: 'post',
        url: `${BASE_SERVICE_URL}/gamelanguage/games/keywords/fix`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 * promise method to handle batch keywords adding
 * @param data
 */
function bulkGamesKeywordsAdd(data = {}) {
    return new ClientHandler({
        method: 'post',
        url: `${BASE_SERVICE_URL}/gamelanguage/games/keywords/add`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 * promise method to handle batch keywords delete
 * @param data
 */
function bulkGamesKeywordsDelete(data = {}) {
    return new ClientHandler({
        method: 'post',
        url: `${BASE_SERVICE_URL}/gamelanguage/games/keywords/delete`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 * promise method to handle keywords import
 * @param data
 */
function importKeywords(data = {}) {
    return new ClientHandler({
        method: 'post',
        url: `${BASE_SERVICE_URL}/gamelanguage/keywords/import`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 * promise method to insert and update of keywords and its translations.
 * @param data
 * @returns {ClientHandler}
 */
function upsertKeywordWithTranslations(data = {}) {
    return new ClientHandler({
        method: 'post',
        url: `${BASE_SERVICE_URL}/gamelanguage/keywords`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 * promise method to handle languages fetch
 * @param params
 * @returns {ClientHandler}
 */
function getAllLanguages(params = {}) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + '/gamelanguage/languages',
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 * promise method to handle language update
 * @param id
 * @param data
 * @returns {ClientHandler}
 */
function updateLanguage(id, data = {}) {
    return new ClientHandler({
        method: 'put',
        url: `${BASE_SERVICE_URL}/gamelanguage/languages/${id}`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 * promise method to handle language adding
 * @param data
 * @returns {ClientHandler}
 */
function addLanguage(data = {}) {
    return new ClientHandler({
        method: 'post',
        url: BASE_SERVICE_URL + '/gamelanguage/languages',
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 * promise method to get whole game with keywords
 * @param id
 * @param params
 * @returns {ClientHandler}
 */
function getSingleGameWithKeywords(id, params) {
    return new ClientHandler({
        method: 'get',
        url: `${BASE_SERVICE_URL}/gamelanguage/games/${id}`,
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 * promise method to get whole game with keywords
 * @param id
 * @param params
 * @returns {ClientHandler}
 */
function getGameKeywords(id, params) {
    return new ClientHandler({
        method: 'get',
        url: `${BASE_SERVICE_URL}/gamelanguage/games/${id}/keywords`,
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 * promise method to add new game
 * @param data
 * @returns {ClientHandler}
 */
function addGame(data) {
    return new ClientHandler({
        method: 'post',
        url: `${BASE_SERVICE_URL}/gamelanguage/games`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 * promise method to update game
 * @param id
 * @param data
 * @returns {ClientHandler}
 */
function updateGame(id, data) {
    return new ClientHandler({
        method: 'put',
        url: `${BASE_SERVICE_URL}/gamelanguage/games/${id}`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 * promise method that fetch all fonts
 * @param params
 * @returns {ClientHandler}
 */
function getAllFonts(params = {}) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + '/gamelanguage/fonts',
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 * promise method that fetch single font
 * @param id
 * @param params
 * @returns {ClientHandler}
 */
function getSingleFonts(id, params = {}) {
    return new ClientHandler({
        method: 'get',
        url: `${BASE_SERVICE_URL}/gamelanguage/fonts/${id}`,
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}


/**
 *
 * @returns {ClientHandler}
 * @param gameID
 * @param data
 */
function updateGameKeywordFontSettings(gameID, data) {
    return new ClientHandler({
        method: 'post',
        url: `${BASE_SERVICE_URL}/gamelanguage/games/${gameID}/keywords`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}


/**
 *
 * @returns {ClientHandler}
 * @param gameID
 * @param data
 */
function addKeywordToGameWithFont(gameID, data) {
    return new ClientHandler({
        method: 'post',
        url: `${BASE_SERVICE_URL}/gamelanguage/games/${gameID}/keywords`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 *
 * @returns {ClientHandler}
 * @param gameID
 * @param data
 */
function uploadJSONFile(gameID, data) {
    return new ClientHandler({
        method: 'post',
        url: `${BASE_SERVICE_URL}/gamelanguage/games/${gameID}/import`,
        onUploadProgress: function (progressEvent) {
            // let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
        },
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 * promise method to handle keyword delete for game language
 * @param gameID
 * @param keywordID
 * @param languageID
 * @returns {ClientHandler}
 */
function deleteKeywordOfGameLanguage(gameID, keywordID, languageID) {
    return new ClientHandler({
        method: 'delete',
        url: `${BASE_SERVICE_URL}/gamelanguage/games/${gameID}/keywords/${keywordID}/languages/${languageID}`,
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

/**
 * promise method to handle keyword delete from game
 * @param gameID
 * @param keywordID
 * @returns {ClientHandler}
 */
function deleteKeywordFromGame(gameID, keywordID) {
    return new ClientHandler({
        method: 'delete',
        url: `${BASE_SERVICE_URL}/gamelanguage/games/${gameID}/keywords/${keywordID}`,
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

/**
 * promise method that fetch all fonts
 * @param params
 * @returns {ClientHandler}
 */
function getAllGames(params) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + '/gamelanguage/games',
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 * promise method that handle font adding
 * @returns {ClientHandler}
 * @param data
 */
function addFont(data) {
    return new ClientHandler({
        method: 'post',
        url: BASE_SERVICE_URL + `/gamelanguage/fonts`,
        headers: {
            'Content-Type': 'application/json',
        },
        data,
    });
}

/**
 * promise method that handle font updating
 * @returns {ClientHandler}
 * @param fontID
 * @param data
 */
function updateFont(fontID, data) {
    return new ClientHandler({
        method: 'put',
        url: BASE_SERVICE_URL + `/gamelanguage/fonts/${fontID}`,
        headers: {
            'Content-Type': 'application/json',
        },
        data,
    });
}

/**
 * promise method that handle seting font as default
 * @returns {ClientHandler}
 * @param fontID
 */
function makeFontAsDefault(fontID) {
    return new ClientHandler({
        method: 'put',
        url: BASE_SERVICE_URL + `/gamelanguage/fonts/${fontID}/default`,
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

/**
 * promise method that handle font delete
 * @param fontID
 * @returns {ClientHandler}
 */
function deleteFont(fontID) {
    return new ClientHandler({
        method: 'delete',
        url: BASE_SERVICE_URL + `/gamelanguage/fonts/${fontID}`,
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

/**
 * Games Keywords Bulk Update
 * @returns {ClientHandler}
 * @param data
 */
function gamesKeywordsBulkUpdate(data) {
    return new ClientHandler({
        method: 'put',
        url: BASE_SERVICE_URL + `/gamelanguage/games/bulk`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 * Get libraries of game
 * @returns {ClientHandler}
 * @param params
 */
function getGameLibraries(params) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + `/deployment/library`,
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 *
 * @param params
 * @returns {ClientHandler}
 */
function getAllRules(params) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + `/gamelanguage/games/rules`,
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 *
 * @param gameID
 * @returns {ClientHandler}
 */
function getGameConfigs(gameID) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + `/gamelanguage/games/${gameID}/config`,
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

/**
 *
 * @param gameID
 * @returns {ClientHandler}
 */
function getBackendConfigs(gameID) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + `/gamelanguage/games/${gameID}/backendconfig`,
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

/**
 *
 * @param gameID
 * @param data
 * @returns {ClientHandler}
 */
function updateGameConfigs(gameID, data) {
    return new ClientHandler({
        method: 'post',
        url: BASE_SERVICE_URL + `/gamelanguage/games/${gameID}/config`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 *
 * @param gameID
 * @param data
 * @returns {ClientHandler}
 */
function updateBackendConfigs(gameID, data) {
    return new ClientHandler({
        method: 'put',
        url: BASE_SERVICE_URL + `/gamelanguage/games/${gameID}/backendconfig`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 *
 * @param gameID
 * @param data
 * @returns {ClientHandler}
 */
function updateGamePaylineCount(gameID, data) {
    return new ClientHandler({
        method: 'put',
        url: BASE_SERVICE_URL + `/gamelanguage/games/${gameID}/paylines`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 *
 * @param gameID
 * @param data
 * @returns {ClientHandler}
 */
function updateGameMaxLimit(gameID, data) {
    return new ClientHandler({
        method: 'put',
        url: BASE_SERVICE_URL + `/gamelanguage/games/${gameID}/maxlimit`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

function updateGameMaxBet(gameID, data) {
    return new ClientHandler({
        method: 'put',
        url: BASE_SERVICE_URL + `/gamelanguage/games/${gameID}/maxbet`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 *
 * @param gameID
 * @param data
 * @returns {ClientHandler}
 */
function updateGameUIType(gameID, data) {
    return new ClientHandler({
        method: 'put',
        url: BASE_SERVICE_URL + `/gamelanguage/games/${gameID}/gameuitype`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 *
 * @param gameID
 * @param data
 * @returns {ClientHandler}
 */
function updateCoreVersion(gameID, data) {
    return new ClientHandler({
        method: 'put',
        url: BASE_SERVICE_URL + `/gamelanguage/games/${gameID}/coreversion`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 *
 * @param params
 * @returns {ClientHandler}
 */
function getGameLanguages(params) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + `/gamelanguage/games/languages`,
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 *
 * @returns {ClientHandler}
 * @param gameID
 * @param languageID
 * @param data
 */
function updateGameLanguages(gameID, languageID, data) {
    return new ClientHandler({
        method: 'put',
        url: BASE_SERVICE_URL + `/gamelanguage/games/${gameID}/languages/${languageID}/enable`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}


function updateProdGameLanguages(gameID, languageID, data) {
    return new ClientHandler({
        method: 'put',
        url: BASE_SERVICE_URL + `/gamelanguage/games/${gameID}/languages/${languageID}/enable/prod`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

function updateGameRtp(gameID, data) {
    return new ClientHandler({
        method: 'put',
        url: BASE_SERVICE_URL + `/gamelanguage/games/${gameID}/rtp`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

function prodIntroVideoVisibility(gameID, status) {
    return new ClientHandler({
        method: 'put',
        url: BASE_SERVICE_URL + `/gamelanguage/games/${gameID}/introvideo/enable/prod`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: {Enabled: status}
    });
}

function prodFeatureScreenVisibility(gameID, status) {
    return new ClientHandler({
        method: 'put',
        url: BASE_SERVICE_URL + `/gamelanguage/games/${gameID}/featurescreen/enable/prod`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: {Enabled: status}
    });
}

function purgeGameServerLanguageCache(game, gameServer, language, languageVersion) {
    const data = {
        game_name: game,
        game_server: gameServer,
        language,
        language_version: languageVersion
    }
    return new ClientHandler({
        method: 'post',
        url: BASE_SERVICE_URL + `/gamelanguage/games/gameserver/languagepurge`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

export const gameManagementServices = {
    getAllKeywords,
    getKeyword,
    getGameKeywords,
    deleteKeyword,
    deleteKeywordWithAllGames,
    exportKeywords,
    overwriteKeyword,
    copyKeywords,
    bulkGamesKeywordsAdd,
    bulkGamesKeywordsDelete,
    importKeywords,
    upsertKeywordWithTranslations,
    getAllLanguages,
    updateLanguage,
    addLanguage,
    getSingleGameWithKeywords,
    getAllFonts,
    getSingleFonts,
    updateGameKeywordFontSettings,
    addKeywordToGameWithFont,
    uploadJSONFile,
    deleteKeywordOfGameLanguage,
    deleteKeywordFromGame,
    getAllGames,
    addFont,
    updateFont,
    makeFontAsDefault,
    deleteFont,
    addGame,
    updateGame,
    gamesKeywordsBulkUpdate,
    getGameLibraries,
    getAllRules,
    getGameConfigs,
    getBackendConfigs,
    updateGameConfigs,
    updateBackendConfigs,
    updateGamePaylineCount,
    updateGameMaxLimit,
    updateGameUIType,
    updateCoreVersion,
    getGameLanguages,
    updateGameLanguages,
    updateProdGameLanguages,
    fixGameKeywords,
    prodIntroVideoVisibility,
    prodFeatureScreenVisibility,
    purgeGameServerLanguageCache,
    getALlGames,
    updateGameRtp,
    updateGameMaxBet
};
