/**
 * check if an object is empty
 * @param obj
 * @returns {boolean}
 */
const isEmptyObject = (obj) => {
    for (let key in obj) {
        if (obj.hasOwnProperty(key))
            return false;
    }
    return true;
}


/**
 * check if a string is a valid JSON string
 * @param string
 * @returns {boolean}
 */
const isJsonString = (string) => {
    try {

        JSON.parse(string)
        return true

    } catch (err) {
        return false
    }
}


/**
 * JSON validation
 * @param input
 * @returns {boolean}
 */
const isValidJSON = (input) => {
    if (!input) return false

    let str = input.toString();

    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
}

export {
    isEmptyObject,
    isValidJSON,
    isJsonString
}
