import React, {useEffect, useState} from 'react';
import {styled} from '@mui/material/styles';
import cx from "classnames";
import ArrowCircleUpIcon from '@mui/icons-material/ArrowUpward';
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const StyledIconButton = styled(IconButton)(({theme}) => ({
    position: "sticky",
    bottom: ".5rem",
    right: "1rem",
    float: "right",
    opacity: 0,
    visibility: "hidden",
    transition: "all .2s",
    transform: "translateY(1.5rem)",
    zIndex: theme.zIndex.tooltip,
    boxShadow: theme.shadows[1],
    marginBottom: "1rem",
    "&:hover": {
        backgroundColor: "#fff",
    },
    "&.active": {
        transform: "translateY(0)",
        opacity: 1,
        visibility: "visible"
    },
}));

function ScrollTop(props) {

    const [visibility, setVisibility] = useState(false);

    useEffect(() => {
        let scrollTarget = document.getElementById("main-content");
        if (!scrollTarget) {
            scrollTarget = window;
        }

        const handleButtonVisibility = (e) => {
            setVisibility(scrollTarget.scrollTop > 100)
        }

        scrollTarget.addEventListener("scroll", handleButtonVisibility)
        return () => scrollTarget.removeEventListener("scroll", handleButtonVisibility)
    }, [])

    const handleClick = () => {
        let scrollTarget = document.getElementById("main-content");
        if (!scrollTarget) {
            scrollTarget = window;
        }

        scrollTarget.scrollTo({top: 0, behavior: "smooth"})
    }

    return (
        <Tooltip title={"Scroll Top"} size={"small"}>
            <StyledIconButton
                className={cx(visibility && "active")}
                color="secondary"
                variant={"filled"}
                onClick={handleClick}
                size="large">
                <ArrowCircleUpIcon/>
            </StyledIconButton>
        </Tooltip>
    );
}

ScrollTop.propTypes = {};

export default ScrollTop