import React from 'react';
import {toast, Slide} from 'react-toastify';

let options = {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    transition: Slide,
};

let LIMIT_FLAG = 0;

/**
 * react-toastify init and override method
 * @type {{success: toasts.success, warning: toasts.warning, error: toasts.error, info: toasts.info}}
 */
export const toasts = {
    checkLimit() {
        if (LIMIT_FLAG > 1) {
            LIMIT_FLAG = 0
            toast.dismiss();
        }
        LIMIT_FLAG++
    },
    error: function (message) {
        this.checkLimit();
        message = <span>{message}</span>;
        toast.error(message, options);
    },
    info: function (message) {
        this.checkLimit();
        message = <span>{message}</span>;
        toast.info(message, options);
    },
    success: function (message, opts) {
        this.checkLimit();
        message = <span>{message}</span>;
        options = Object.assign(options, opts);
        toast.success(message, options);
    },
    warning: function (message) {
        this.checkLimit();
        message = <span>{message}</span>;
        toast.warning(message, options);
    },
};
