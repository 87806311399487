/**
 * Redux actions for the settings of the App
 */
import {dataConstants} from "../constants";
import {sharedDataServices} from "../services/shared-data.service";

export const dataActions = {
    setAllGames,
    setLanguages,
    setCurrencies,
    setCountries,
    setJurisdictions,
    setConstData,
    setData,
    addNotifications,
    clearNotifications,
    setUnreadNotifications,
};

/**
 * action to set data
 * @param data
 * @returns {Function}
 */
function setData(data) {
    return dispatch => {
        dispatch({
            type: dataConstants.SET_DATA,
            payload: data
        })
    }
}

/**
 * action to set all games
 * @param data
 * @returns {Function}
 */
function setAllGames(data) {
    sharedDataServices.setLocalData("ALL_GAMES", data);
    return dispatch => {
        dispatch({
            type: dataConstants.SET_ALL_GAMES,
            payload: data
        })
    }
}

/**
 * action to set data languages
 * @param data
 * @returns {Function}
 */
function setLanguages(data) {
    sharedDataServices.setLocalData("LANGUAGES", data);
    return dispatch => {
        dispatch({
            type: dataConstants.SET_LANGUAGES,
            payload: data
        })
    }
}

/**
 * action to set data currencies
 * @param data
 * @returns {Function}
 */
function setCurrencies(data) {
    sharedDataServices.setLocalData("CURRENCIES", data);
    return dispatch => {
        dispatch({
            type: dataConstants.SET_CURRENCIES,
            payload: data
        })
    }
}

/**
 * action to set data countries
 * @param data
 * @returns {Function}
 */
function setCountries(data) {
    sharedDataServices.setLocalData("COUNTRIES", data);
    return dispatch => {
        dispatch({
            type: dataConstants.SET_COUNTRIES,
            payload: data
        })
    }
}


/**
 * action to set data jurisdictions
 * @param data
 * @returns {Function}
 */
function setJurisdictions(data) {
    sharedDataServices.setLocalData("JURISDICTIONS", data);
    return dispatch => {
        dispatch({
            type: dataConstants.SET_JURISDICTIONS,
            payload: data
        })
    }
}

/**
 * action to set static data
 * @param data
 * @returns {Function}
 */
function setConstData(data) {
    sharedDataServices.setLocalData("CONST_DATA", data);
    return dispatch => {
        dispatch({
            type: dataConstants.SET_CONST_DATA,
            payload: data
        })
    }
}

/**
 * action to set all notifications
 * @param data
 * @returns {Function}
 */
function setUnreadNotifications(data) {
    return dispatch => {
        dispatch({
            type: dataConstants.SET_UNREAD_NOTIFICATIONS,
            payload: data
        })
    }
}

/**
 * action to add new notifications
 * @param data
 * @returns {Function}
 */
function addNotifications(data) {
    return dispatch => {
        dispatch({
            type: dataConstants.ADD_UNREAD_NOTIFICATIONS,
            payload: data
        })
    }
}

/**
 * action to clear notifications
 * @returns {Function}
 */
function clearNotifications() {
    return dispatch => {
        dispatch({
            type: dataConstants.CLEAR_UNREAD_NOTIFICATIONS,
        })
    }
}
