import React, {useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import cx from "classnames";
import {settingsActions} from "../../actions/settings.actions";
import {authActions} from "../../actions/auth.actions";
import {history, newRoutes} from "../../utils";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from '@mui/material/MenuItem';
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import {styled} from '@mui/material/styles';
import Badge from "@mui/material/Badge";
import NotifyService from "../../utils/notification-service";
import {dataActions} from "../../actions/data.actions";
import NotificationsPopup from "../notifications-popup";
import {notificationsService} from "../../services/notifications.service";
import {Avatar, Box, Button, Divider, ListItem, ListItemText, Paper} from "@mui/material";
import {CgMenuLeft} from "react-icons/cg";
import {findConstDataTarget} from "../../services/shared-data.service";
import Icons from "../icons";
import GeneralSearch from "../general-search-modal";
import {useHotkeys} from "react-hotkeys-hook";

function GeneralSearchButton({...props}) {
    return (
        <Button sx={{
            border: "1px solid",
            borderRadius: 1,
            py: .25,
            px: .8,
            width: 110,
            display: "inline-flex",
            justifyContent: "flex-end",
            alignItems: "center",
            fontSize: 12,
            position: "relative",
            borderColor: "text.hint",
            mr: .75
        }}
                {...props}>
            <Box sx={{
                fontSize: 24, position: "absolute", left: 6, top: -3,
            }}>
                <Icons.Detail/>
            </Box>

            <Box sx={{color: "text.disabled"}}>Ctrl+K</Box>
        </Button>
    )
}

const StyledCgMenuLeft = styled(CgMenuLeft)(({theme}) => {
    return ({
        "&.closed > path:nth-child(3)": {
            transform: `translateX("6px")`
        }
    })
})

function MainHeader({isSidebarClosed}) {

    const dispatch = useDispatch();
    const mainSidebarCloseStatus = useSelector(state => state.settings.main_sidebar_close);
    const darkMode = useSelector(state => state.settings.darkMode);
    const unreadNotifications = useSelector(state => state.data.unreadNotifications);
    const admin = useSelector(state => state.auth.admin);

    const popupNotifications = useMemo(() => unreadNotifications.slice(0, 10), [unreadNotifications]);

    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);

    const [notificationsPalletAnchorEl, setNotificationsPalletAnchorEl] = useState(null);

    const [generalSearchOpen, setGeneralSearchOpen] = useState(false);

    useHotkeys("ctrl+k,command+k", () => setGeneralSearchOpen(true))

    const isMenuOpen = Boolean(userMenuAnchorEl);

    useEffect(() => {
        function onResize() {
            if (window.innerWidth < 769 && !mainSidebarCloseStatus) {
                dispatch(settingsActions.toggleMainMenu(true))
            }
        }

        window.addEventListener("resize", onResize)
        return () => window.removeEventListener("resize", onResize)
    }, [])

    useEffect(() => {
        NotifyService.onNotify = (data) => {
            dispatch(dataActions.addNotifications(data))
        }
    }, [])

    // open/close the main sidebar
    function handleSidebarToggle() {
        dispatch(settingsActions.toggleMainMenu(!mainSidebarCloseStatus))
    }

    function handleLogout() {
        dispatch(authActions.logout())
        history.push(newRoutes.login.path);
    }

    function handleNotificationRead(NotificationId = null) {
        dispatch(dataActions.clearNotifications());

        notificationsService.markAsRead({NotificationId}).fire()
            .finally(() => {
                let updatedNotifications = [];
                if (!!NotificationId)
                    updatedNotifications = unreadNotifications.filter(n => n.Id !== NotificationId)

                dispatch(dataActions.setUnreadNotifications(updatedNotifications))
            })
    }

    function handleProfileMenuOpen(event) {
        setUserMenuAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setUserMenuAnchorEl(null);
    }

    function handleProfileItemClick() {
        history.push(newRoutes.profile.path);
        handleMenuClose();
    }

    const menuId = 'primary-search-account-menu';
    const menuItemSx = {borderRadius: 1, py: .75}

    let userTitle = admin.FirstName ? admin.FirstName + " " + admin.LastName : admin.Email
    let userSubTitle = findConstDataTarget("UserGroup", admin.UserGroup)

    const renderMenu = (
        <Menu
            sx={{"& .Mui-disabled": {color: "text.primary"}, elevation: 0}}
            PaperProps={{sx: {p: 0, width: 260, mt: 3, boxShadow: 1}}}
            anchorEl={userMenuAnchorEl}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            id={menuId}
            keepMounted
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
            open={isMenuOpen}
            onClose={handleMenuClose}
            MenuListProps={{
                'aria-labelledby': 'lock-button',
                role: 'listbox',
                sx: {p: 0}
            }}
        >
            <ListItem button={false} sx={{px: 2, py: 1}}>
                <ListItemText
                    sx={{m: 0, fontWeight: 500}}
                    primary={<Typography variant={"body2"}>
                        {userTitle}
                    </Typography>}
                    secondary={<Typography variant={"subtitle2"}>
                        {userSubTitle}
                    </Typography>}
                />
            </ListItem>
            <Divider/>
            <Box sx={{p: .75}}>
                <MenuItem onClick={handleProfileItemClick} sx={menuItemSx}>
                    <Typography variant="body2">Edit Profile</Typography>
                </MenuItem>
                <MenuItem onClick={handleLogout} sx={menuItemSx}>
                    <Typography variant="body2">Logout</Typography>
                </MenuItem>
            </Box>
        </Menu>
    )

    const renderNotificationsPallet = (
        <NotificationsPopup notifications={popupNotifications}
                            unReadCount={unreadNotifications.length}
                            anchorEl={notificationsPalletAnchorEl}
                            onRead={handleNotificationRead}
                            onClose={() => setNotificationsPalletAnchorEl(null)}/>
    )

    return (
        < >
            <AppBar elevation={0} sx={{
                transition: "all .5s ease",
                width: isSidebarClosed ? "100%" : "calc(100% - 256px + 1rem)",
                backgroundColor: "background.blur", color: "text.primary",
                backdropFilter: `blur(6px)`
            }}>
                <Toolbar sx={{
                    p: 1,
                    px: [1.5, "!important"],
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    alignItems: "center"
                }}>
                    <IconButton
                        edge="start"
                        onClick={handleSidebarToggle}
                        aria-label="menu">
                        <StyledCgMenuLeft className={cx(mainSidebarCloseStatus && "closed")}/>
                    </IconButton>
                    <Box>
                        <GeneralSearchButton onClick={() => setGeneralSearchOpen(true)}/>
                        <IconButton
                            onClick={() => dispatch(settingsActions.setDarkMode(!darkMode))}>
                            {darkMode ? <Icons.LightMode/> : <Icons.DarkMode/>}
                        </IconButton>

                        <IconButton
                            sx={{mr: .5}}
                            onClick={e => setNotificationsPalletAnchorEl(e.currentTarget)}>
                            <Badge badgeContent={unreadNotifications.length} color="secondary">
                                <Icons.Notifications/>
                            </Badge>
                        </IconButton>
                        <IconButton onClick={handleProfileMenuOpen} size={"small"}>
                            <Avatar>{admin.FirstName?.[0] || admin.Email?.[0]}</Avatar>
                        </IconButton>
                    </Box>
                </Toolbar>
            </AppBar>
            {renderMenu}
            {renderNotificationsPallet}
            <GeneralSearch open={generalSearchOpen} onClose={() => setGeneralSearchOpen(false)}/>
        </>
    )
}

export default MainHeader;
