import {green, red, blue, grey} from '@mui/material/colors';
import {darken} from "@mui/material";
import {lighten} from "@mui/material/styles";

const allThemes = {
    default: {
        palette: {
            type: 'light',
            primary: {
                light: lighten("#00ab55", .25),
                main: '#00ab55',
                dark: darken('#00ab55', .25)
            },
            secondary: {
                light: lighten("#ea9b0a", .25),
                main: '#ea9b0a',
                dark: darken('#00ab55', .25),
                contrastText: '#272727'
            },
            background: {
                paper: '#fcfcfc',
                paperDark: darken("#fcfcfc", .05),
                default: '#047373',
                contrast: 'rgba(0,0,0, 0.5)',
                blur: 'rgba(255, 255, 255, 0.8)',
                primaryText: "rgba(84, 214, 44, 0.16)",
                secondaryText: "rgba(234,155,10,0.16)",
                infoText: "rgba(66,142,255,0.16)",
                dangerText: "rgba(255, 72, 66, 0.16)",
            },
            danger: {
                light: lighten(red[500], .25),
                main: red[500],
                dark: darken(red[500], .25),
                contrastText: '#fcfcfc'
            },
            info: {
                light: lighten(blue[500], .25),
                main: blue[500],
                dark: darken(blue[500], .25),
                contrastText: '#fcfcfc'
            },
            error: red,
            success: green,
            text: {
                primary: 'rgba(0,0,0,0.88)',
                secondary: 'rgba(0,0,0,0.54)',
                disabled: 'rgba(0,0,0,0.38)',
                hint: 'rgba(0,0,0,0.38)',
            },
        },
        typography: {
            fontWeightExtraBold: 800,
            h1: {
                fontSize: "clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem)",
                fontWeight: 800,
                lineHeight: 1.11428
            },
            h2: {
                fontSize: "clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)",
                fontWeight: 800,
                lineHeight: 1.2222223,
            },
            h3: {
                fontSize: "1.875rem",
                lineHeight: 1.2222223,
                fontWeight: 400
            },
            h4: {
                fontSize: "1.25rem",
                lineHeight: 1.5,
                fontWeight: 700
            },
            h5: {
                fontSize: "1.15rem",
                lineHeight: 1.5,
                fontWeight: 400
            },
            h6: {
                fontSize: "1.1rem",
                lineHeight: 1.5,
                fontWeight: 500
            },
            button: {
                textTransform: "none",
                fontWeight: 700,
                fontSize: "0.9375rem",
                lineHeight: 1.75
            },
            subtitle1: {
                fontSize: "1.125rem",
                lineHeight: 1.3333333333333333,
                fontWeight: 500,
            },
            body1: {
                fontSize: "1rem",
                lineHeight: 1.2,
                fontWeight: 400
            },
            body2: {
                fontSize: "0.875rem",
                lineHeight: 1.1,
                fontWeight: 400
            },
            caption: {
                display: "inline-block",
                fontSize: "0.75rem",
                lineHeight: 1.2,
                fontWeight: 700,
            },
            htmlFontSize: 14,
            fontSize: 14,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 700,
            subtitle2: {
                fontWeight: 500,
                fontSize: "0.875rem",
                lineHeight: 1.3
            },
            overline: {
                fontWeight: 400,
                fontSize: "0.75rem",
                lineHeight: 2.66,
                textTransform: "uppercase"
            },
        },
        spacing: 12,
        status: {
            danger: 'orange'
        },
        shape: {
            borderRadius: 8
        },
        shadows: [
            "none",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(0,171,85,.24) 0px 6px 16px 0px",
        ],
        components: {
            MuiSwitch: {
                styleOverrides: {
                    switchBase: {
                        color: darken("#fcfcfc", .05)
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {},
                },
            },
            MuiBackdrop: {
                styleOverrides: {
                    root: {
                        backdropFilter: "blur(2px)"
                    },
                },
            }
        },
    },
    dangerLight: {
        palette: {
            type: 'light',
            primary: red,
            secondary: grey,
            background: {
                paper: '#fcfcfc',
                paperDark: darken("#fcfcfc", .05),
                default: '#047373',
                contrast: 'rgba(0,0,0, 0.5)',
                blur: 'rgba(255, 255, 255, 0.8)',
                primaryText: "rgba(84, 214, 44, 0.16)",
                secondaryText: "rgba(234,155,10,0.16)",
                infoText: "rgba(66,142,255,0.16)",
                dangerText: "rgba(255, 72, 66, 0.16)",
            },
            danger: {
                light: lighten(red[500], .25),
                main: red[500],
                dark: darken(red[500], .25),
                contrastText: '#fcfcfc'
            },
            info: {
                light: lighten(blue[500], .25),
                main: blue[500],
                dark: darken(blue[500], .25),
                contrastText: '#fcfcfc'
            },
            error: red,
            success: green,
            text: {
                primary: 'rgba(0,0,0,0.88)',
                secondary: 'rgba(0,0,0,0.54)',
                disabled: 'rgba(0,0,0,0.38)',
                hint: 'rgba(0,0,0,0.38)',
            },
        },
    },
    dark: {
        palette: {
            type: 'dark',
            action: {
                activatedOpacity: 0.12,
                active: "rgba(255,255,255, 0.54)",
                disabled: "rgba(255,255,255, 0.26)",
                disabledBackground: "rgba(255,255,255, 0.12)",
                disabledOpacity: 0.38,
                focus: "rgba(255,255,255, 0.12)",
                focusOpacity: 0.12,
                hover: "rgba(255,255,255, 0.04)",
                hoverOpacity: 0.04,
                selected: "rgba(255,255,255, 0.08)",
                selectedOpacity: 0.08,
            },
            primary: {
                light: lighten("#00ab55", .25),
                main: '#00ab55',
                dark: darken('#00ab55', .25)
            },
            secondary: {
                light: lighten("#ea9b0a", .25),
                main: '#ea9b0a',
                dark: darken('#00ab55', .25),
                contrastText: '#272727'
            },
            background: {
                paper: '#0c110f',
                paperDark: lighten("#0c110f", .05),
                default: '#303030',
                contrast: 'rgba(255,255,255,0.5)',
                blur: 'rgba(0,0,0,0.8)',
                primaryText: "rgba(84, 214, 44, 0.16)",
                secondaryText: "rgba(234,155,10,0.16)",
                infoText: "rgba(66,142,255,0.16)",
                dangerText: "rgba(255, 72, 66, 0.16)",
            },
            danger: {
                light: lighten(red[500], .25),
                main: red[500],
                dark: darken(red[500], .25),
                contrastText: '#fcfcfc'
            },
            info: {
                light: lighten(blue[500], .25),
                main: blue[500],
                dark: darken(blue[500], .25),
                contrastText: '#fcfcfc'
            },
            error: red,
            success: green,
            text: {
                primary: 'rgba(255,255,255,0.65)',
                secondary: 'rgba(255,255,255,0.54)',
                disabled: 'rgba(255,255,255,0.38)',
                hint: 'rgba(255,255,255,0.38)',
            },
        },
        typography: {
            fontWeightExtraBold: 800,
            h1: {
                fontSize: "clamp(2.625rem, 1.2857rem + 3.5714vw, 4rem)",
                fontWeight: 800,
                lineHeight: 1.11428
            },
            h2: {
                fontSize: "clamp(1.5rem, 0.9643rem + 1.4286vw, 2.25rem)",
                fontWeight: 800,
                lineHeight: 1.2222223,
            },
            h3: {
                fontSize: "1.875rem",
                lineHeight: 1.2222223,
                fontWeight: 400
            },
            h4: {
                fontSize: "1.25rem",
                lineHeight: 1.5,
                fontWeight: 700
            },
            h5: {
                fontSize: "1.15rem",
                lineHeight: 1.5,
                fontWeight: 400
            },
            h6: {
                fontSize: "1.1rem",
                lineHeight: 1.5,
                fontWeight: 500
            },
            button: {
                textTransform: "none",
                fontWeight: 700,
                fontSize: "0.9375rem",
                lineHeight: 1.75
            },
            subtitle1: {
                fontSize: "1.125rem",
                lineHeight: 1.3333333333333333,
                fontWeight: 500,
            },
            body1: {
                fontSize: "1rem",
                lineHeight: 1.2,
                fontWeight: 400
            },
            body2: {
                fontSize: "0.875rem",
                lineHeight: 1.1,
                fontWeight: 400
            },
            caption: {
                display: "inline-block",
                fontSize: "0.75rem",
                lineHeight: 1.2,
                fontWeight: 700,
            },
            htmlFontSize: 14,
            fontSize: 14,
            fontWeightLight: 300,
            fontWeightRegular: 400,
            fontWeightMedium: 500,
            fontWeightBold: 700,
            subtitle2: {
                fontWeight: 500,
                fontSize: "0.875rem",
                lineHeight: 1.3
            },
            overline: {
                fontWeight: 400,
                fontSize: "0.75rem",
                lineHeight: 2.66,
                textTransform: "uppercase"
            },
        },
        spacing: 12,
        status: {
            danger: 'orange'
        },
        shape: {
            borderRadius: 8
        },
        shadows: [
            "none",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(145, 158, 171, .2) 0px 0px 2px 0px, rgb(145, 158, 171, .12) 0px 12px 24px -4px",
            "rgb(0,171,85,.24) 0px 6px 16px 0px",
        ],
        components: {
            MuiSwitch: {
                styleOverrides: {
                    switchBase: {
                        color: darken("#282828", .05)
                    },
                },
            },
            MuiIconButton: {
                styleOverrides: {
                    root: {
                        color: "inherit"
                    },
                },
            },
            MuiButtonBase: {
                styleOverrides: {
                    root: {
                        color: "inherit"
                    },
                },
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    notchedOutline: {
                        borderColor: "rgba(255,255,255, 0.4)"
                    },
                },
            },
        },
    },
};

export default allThemes;
