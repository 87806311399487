import React, {useMemo} from 'react';
import {connect} from "react-redux";
import {Scrollbars} from "react-custom-scrollbars";
import NestedNavigation from "../nested-navigation/nested-navigation";
import SidebarImg01 from "../../assets/images/sidebar-01.jpg";
import {withRouter} from "react-router-dom";
import config from "../../config.json";
import {styled} from "@mui/material/styles";
import menuItems from "../../constants/manu-items.constants";

const SidebarTitle = styled("div")(({theme}) => ({
    position: "relative",
    marginBottom: "1rem",
    fontSize: ".88rem",
    fontWeight: 700,
    color: theme.palette.primary.main,
    "& a": {
        color: "inherit"
    }
}))

const PREFIX = 'main-sidebar';

const classes = {
    mainSidebar: `${PREFIX}-mainSidebar`,
    sidebarTitle: `${PREFIX}-sidebarTitle`,
    link: `${PREFIX}-link`
};

const StyledAsideRoot = styled('aside')(({
                                             theme: {palette, shadows, zIndex}
                                         }) => {

    const paper = palette.background.paper;
    const paperColorContrast = palette.getContrastText(paper);

    return {
        width: 240,
        maxWidth: 240,
        height: "100vh",
        overflow: "hidden",
        backgroundColor: palette.background.blur,
        backdropFilter: "blur(6px)",
        opacity: 1,
        transition: "all .5s ease",
        position: "fixed",
        zIndex: zIndex.drawer,
        "& .main-scrollbar > div:nth-child(1)": {
            padding: "1.5rem 0.75rem",
        },
        [`& .${classes.mainSidebar}`]: {
            color: paperColorContrast,
            boxShadow: shadows[2],
            backgroundImage: `url(${SidebarImg01})`,
            "&:before": {
                backgroundColor: paper
            }
        },
        [`& .${classes.link}`]: {
            color: paperColorContrast,
        }
    };
});

function MainSidebar(props) {

    const {mainSidebarCloseStatus, location = {}} = props

    let activeNavLink = location.pathname;

    // find open nav and submenu after component render...
    const openNavs = useMemo(() => findOpenNavs(menuItems), [menuItems])

    function findOpenNavs(items) {
        let openNavs = [];
        items.forEach(item => {

            if (item.subMenus) {
                item.subMenus.map(si => {
                    if (si.key === activeNavLink) {
                        openNavs = [...openNavs, item.key]
                    }
                })
            }
        })

        return openNavs;
    }

    let sidebarSx = {
        transform: "translateX(0)",
        boxShadow: 1
    }

    if (mainSidebarCloseStatus) {
        sidebarSx = {
            ...sidebarSx,
            opacity: 0,
            transform: "translateX(-100%)"
        }
    }

    return (
        (<StyledAsideRoot sx={sidebarSx}>
            <Scrollbars
                autoHide
                className="main-scrollbar"
                autoHideTimeout={300}
                autoHideDuration={200}
            >

                <SidebarTitle sx={{px: 1}}>
                    <a href="/">{config.APP_NAME}</a>
                </SidebarTitle>

                <NestedNavigation
                    openKeys={openNavs}
                    selectedKey={activeNavLink}
                    menus={menuItems}/>
            </Scrollbars>
            <div className={"sidebar-bg"} style={{backgroundImage: `url(${SidebarImg01})`}}/>
        </StyledAsideRoot>)
    );

}

function mapStateToProps(state) {
    return {
        mainSidebarCloseStatus: state.settings.main_sidebar_close
    }
}

export default connect(mapStateToProps)(withRouter(MainSidebar));
