import config from '../config.json';
import SingletonFactory from './singleton-factory';

class NotificationService {
  constructor() {
    this.subscription = null;
    this.swRegistration = null;
    this._events = [];
  }

  isWSSupported() {
    return 'serviceWorker' in navigator;
  }

  isWebPushSupported() {
    return 'PushManager' in window;
  }

  handleServiceWorkerMessages(e) {
    if (!e || !e.data.type) return;

    this._fireEvents(e.data.type, e.data.payload);
  }

  requestPermission() {
    Notification.requestPermission().then((res) => res === 'granted' && this.subscribeUser());
  }

  start() {
    if (!this.isWSSupported()) return;

    navigator.serviceWorker.addEventListener(
      'message',
      this.handleServiceWorkerMessages.bind(this)
    );

    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    // register service worker
    const registerWSonLoad = () => {
      const swUrl = `${process.env.PUBLIC_URL}/notification-sw.js`;

      navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
          if (this.isWebPushSupported()) {
            this.swRegistration = registration;

            if (Notification.permission === 'granted') this.subscribeUser();
          }
        })
        .catch((error) => {
          console.error('Service Worker registration error:', error);
        });
    };

    window.addEventListener('load', registerWSonLoad);
  }

  stop() {
    if (!this.isWSSupported()) {
      return;
    }

    navigator.serviceWorker.removeEventListener(
      'message',
      this.handleServiceWorkerMessages.bind(this)
    );

    navigator.serviceWorker.ready.then((registration) => {
      this.subscription = null;
      this.swRegistration = null;
      registration.unregister();
    });
  }

  subscribeUser() {
    if (!this.swRegistration) return;

    this.swRegistration.pushManager
      .getSubscription()
      .then((subscription) => {
        if (subscription) return subscription;

        return this.swRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: this._urlBase64ToUint8Array(config.WEB_PUSH_PUBLIC_KEY),
        });
      })
      .then((subscription) => {
        this.subscription = subscription;

        this._fireEvents('subscription', this.subscription);
      })
      .catch((err) => {
        console.log(err);
        if (Notification.permission === 'denied') {
          console.warn('Notification permission denied');
        } else {
          console.error('Failed to subscribe: ', err);
        }
      });
  }

  unsubscribeUser() {
    this.swRegistration.pushManager
      .getSubscription()
      .then((subscription) => {
        if (subscription) return subscription;
      })
      .catch((e) => {
        console.error('Error unsubscribing', e);
      })
      .then(() => {
        console.log('User is unsubscribed');
        this.subscription = null;
      });
  }

  set onSubscription(fn) {
    this._addEvent('subscription', fn);
  }

  set onNotify(fn) {
    this._addEvent('notify', fn);
  }

  isSubscribed() {
    return !!this.subscription || Notification.permission === 'granted';
  }

  _addEvent(type, fn) {
    if (!fn || typeof fn !== 'function' || !type) return;

    this._events.push({ type, fn });
  }

  _fireEvents(type, ...args) {
    this._events.map((e) => {
      if (e.type === type) e.fn.apply(this, args);
    });
  }

  _urlBase64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    let base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/')
      .replace(/\r?\n|\r/g, '');

    const rawData = window.atob(base64.toString());
    return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
  }
}

// make a singleton of service
const NotifyService = SingletonFactory(NotificationService).getInstance();

export default NotifyService;
