import { isJsonString } from './js-tools/json-validator';

const defaultBodyTemplate = 'by {PerformerFirstname} {PerformerLastname} \non {DateTime}';

let NOTIFICATIONS_TYPES = {};

// load NOTIFICATION_TYPES
fetch(`${process.env.PUBLIC_URL}/notification-types.js`)
  .then((res) => res.text())
  .then((content) => {
    if (content.indexOf('const NOTIFICATIONS_TYPES = ') === 0) {
      content = content.replace('const NOTIFICATIONS_TYPES = ', '');
      if (isJsonString(content)) {
        NOTIFICATIONS_TYPES = JSON.parse(content);
      } else {
        throw new Error(
          `"${process.env.PUBLIC_URL}/notification-types.js" has invalid content.\n` +
            `Here is the sample content, "const NOTIFICATIONS_TYPES = {}", notice that the value should be a valid JSON`
        );
      }
    } else {
      throw new Error(
        `"${process.env.PUBLIC_URL}/notification-types.js" has invalid content.\n` +
          `Here is the sample content, "const NOTIFICATIONS_TYPES = {}", notice that the value should be a valid JSON`
      );
    }
  });

export default function parseNotificationData(data = {}) {
  if (!data || !data.Type) {
    return;
  }

  if (!(data.Type in NOTIFICATIONS_TYPES)) return;

  const NOTIFY = NOTIFICATIONS_TYPES[data.Type] || {};
  let title = parseNotifyTemplate(NOTIFY.title, data) || data.Type;
  let body = parseNotifyTemplate(NOTIFY.body || defaultBodyTemplate, data);
  let targetURL = parseNotifyTemplate(NOTIFY.targetURL, data);

  return { ...data, title, body, targetURL };
}

function parseNotifyTemplate(template = '', data = {}) {
  if (!template) return template;

  return template.replace(/\{\w+\}/g, function (match) {
    let key = match.substr(1, match.length - 2);

    let value = data[key] || '';
    if (key === 'DateTime') {
      const date = new Date(data.Unix * 1000 || data.Date);
      value = date.toLocaleString();
    } else if (key === 'origin' && window.location) {
      value = window.location.origin;
    } else if (key === 'defaultBodyTemplate') {
      value = parseNotifyTemplate(defaultBodyTemplate, data);
    }

    return value;
  });
}
