import {isJsonString, reduxStore} from "../utils";
import {ClientHandler} from "js-service-wrapper";
import BASE_SERVICE_URL from "./get-service-url";

/**
 * promise method to handle jurisdictions fetch
 * @param params
 */
function getAllJurisdictions(params = {}) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + '/account/jurisdictions',
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 * promise method to handle countries fetch
 * @param params
 */
function getAllCountries(params = {}) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + '/account/countries',
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 * promise method to handle currencies fetch
 * @param params
 */
function getAllCurrencies(params = {}) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + '/account/currencies',
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

function getConstData() {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + `/const`,
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

/**
 * Set local storage data with expire time
 *
 * @param key
 * @param value
 * @param expiresAfterMS - Default 24 hour. set 0 to skip expire time
 * @returns {boolean}
 */
function setLocalData(key, value, expiresAfterMS = 1000 * 60 * 60 * 24) {
    try {
        if (!expiresAfterMS || !Number.isInteger(expiresAfterMS)) {
            localStorage.setItem(key, JSON.stringify(value))
        } else {
            let now = new Date().getTime()
            let expiresAt = now + expiresAfterMS
            localStorage.setItem(key, JSON.stringify({
                value,
                expiresAt
            }));
        }

        return true
    } catch (err) {
        return false
    }

}

/**
 * Get expiry local data
 *
 * @param key
 * @returns {string|null|any}
 */
function getLocalData(key = null) {
    if (key === null) return null

    const dataStr = localStorage.getItem(key);

    if (isJsonString(dataStr)) {
        const data = JSON.parse(dataStr)
        if (data && !!data.expiresAt) {
            let now = new Date().getTime()
            if (data.expiresAt >= now) {
                return data.value
            } else {
                localStorage.removeItem(key)
                return null
            }
        } else {
            return data
        }
    }

    return dataStr
}

function getConstValuesByKey(key, def, state) {
    // to prevent call on reduxStore you can pass a manual state.
    const {constData = {}} = state || reduxStore.getState().data;

    return constData[key] || def;
}

export function findConstDataTarget(on, val, def = null, state) {

    // to prevent call on reduxStore you can pass a manual state.
    const {constData = {}} = state || reduxStore.getState().data;

    if (!constData || !(on in constData)) {
        return def || val;
    }

    if (constData[on].toString() !== "[object Object]") {
        return def || val;
    }

    for (let [key, value] of Object.entries(constData[on])) {
        if (value === val)
            return key
    }

    return def || val;
}

/**
 * generate select options for const types
 * @returns {[]|Array}
 */
export function generateConstDataOptions(name, valueRenderer = null) {
    const types = getConstValuesByKey(name, []);
    if (!types || !Object.keys(types).length)
        return [];

    let options = [];
    for (let t in types) {
        if (types.hasOwnProperty(t))
            options.push({
                id: t,
                title: t,
                value: valueRenderer && typeof valueRenderer === "function"
                    ? valueRenderer(t, types)
                    : types[t]
            })
    }

    return options;
}

export const sharedDataServices = {
    getAllJurisdictions,
    getAllCountries,
    getAllCurrencies,
    getConstData,
    getConstValuesByKey,
    getLocalData,
    setLocalData,
};
