import React, {useEffect} from "react";
import {sharedDataServices} from "../../services/shared-data.service";
import {authService} from "../../services/auth.service";
import {isJsonString, reduxStore} from "../../utils";
import {dataActions} from "../../actions/data.actions";
import {gameManagementServices} from "../../services/game-management.service";

/**
 * Hook to handle data fetch
 */
export default function useDataInit() {

    useEffect(() => {
        if (authService.isAuthenticated() && !authService.forcePasswordChange())
            getGlobalData()
    }, [])

}

export function getGlobalData() {
    fetchAllGames()
        .then(res => {
            reduxStore.dispatch(dataActions.setAllGames(res))
        })

    fetchLanguages()
        .then(res => {
            reduxStore.dispatch(dataActions.setLanguages(res))
        })

    fetchCurrencies()
        .then(res => {
            reduxStore.dispatch(dataActions.setCurrencies(res))
        })

    fetchCountries()
        .then(res => {
            reduxStore.dispatch(dataActions.setCountries(res))
        })

    fetchJurisdictions()
        .then(res => {
            reduxStore.dispatch(dataActions.setJurisdictions(res))
        })

    fetchConstData()
        .then(res => {
            reduxStore.dispatch(dataActions.setConstData(res))
        })

}

async function fetchAllGames() {
    let localData = sharedDataServices.getLocalData("ALL_GAMES")
    if (localData) {
        return localData
    }

    const gamesRes = await gameManagementServices.getALlGames({}).fire({parallel: true})
    return gamesRes.Games
}

async function fetchLanguages() {
    let localData = sharedDataServices.getLocalData("LANGUAGES")
    if (localData) {
        return localData
    }

    const languagesRes = await gameManagementServices.getAllLanguages().fire({parallel: true})
    return languagesRes.Languages
}

async function fetchCurrencies() {
    let localData = sharedDataServices.getLocalData("CURRENCIES")
    if (localData) {
        return localData
    }

    const currenciesRes = await sharedDataServices.getAllCurrencies().fire({parallel: true})
    return currenciesRes.Currencies
}

async function fetchCountries() {
    let localData = sharedDataServices.getLocalData("COUNTRIES")
    if (localData) {
        return localData
    }

    const countriesRes = await sharedDataServices.getAllCountries().fire({parallel: true})
    return countriesRes.Countries
}

async function fetchJurisdictions() {
    let localData = sharedDataServices.getLocalData("JURISDICTIONS")
    if (localData) {
        return localData
    }

    const jurRes = await sharedDataServices.getAllJurisdictions().fire({parallel: true})
    return jurRes.Jurisdictions
}

async function fetchConstData() {
    let localData = sharedDataServices.getLocalData("CONST_DATA")
    if (localData) {
        return localData
    }

    return await sharedDataServices.getConstData().fire({parallel: true})
}
