import {newRoutes} from "../utils";
import Icons from "../components/icons";

const {
    dashboard,topGames,topGamesStatistic, usersList, userAdd,
    customerHistory, jurisdictions, elasticExport,
    freeTickets, playedFreeTickets, releasesList,
    gamesOfSites, sitesOfGame, versions, gamesList, keywordsList,
    languages, fonts, libraries, rulesEditor, rulesOfGames, rulesSharedStyles,
    emailingNewEmail, emailingMails, emailingContacts,
    betConfigsAccounts, betConfigsSites, betConfigsNew, betConfigsList, betConfigsLimits,
    gameTechUsers, gameTechNewArea, gameTechLocations, customers,
} = newRoutes

function routeObjToItem(route) {
    return {
        key: route.path,
        label: route.title,
        path: route.path
    }
}

const menuItems = [
    {...routeObjToItem(dashboard), icon: Icons.Dashboard},
    {
        key: 'statistic-tool-management-management',
        label: 'Statistic Tool Management',
        separated: false,
        icon: Icons.Statistic,
        subMenus: [
            {...routeObjToItem(topGames)},
            {...routeObjToItem(topGamesStatistic)},
        ],
    },
    {
        key: 'user-management',
        label: 'Users Management',
        separated: false,
        icon: Icons.Users,
        subMenus: [
            {...routeObjToItem(usersList)},
            {...routeObjToItem(userAdd)},
        ],
    },
    {
        key: 'account-management',
        label: 'Customer Management',
        separated: false,
        icon: Icons.Customer,
        subMenus: [
            {...routeObjToItem(customers)},
            {...routeObjToItem(customerHistory)},
            {...routeObjToItem(jurisdictions)},
            {...routeObjToItem(elasticExport)},
            {...routeObjToItem(freeTickets)},
            {...routeObjToItem(playedFreeTickets)},
        ],
    },
    {
        key: 'deploy-management',
        label: 'Deploy Management',
        separated: false,
        icon: Icons.Rocket,
        subMenus: [
            {...routeObjToItem(releasesList)},
            {...routeObjToItem(gamesOfSites)},
            {...routeObjToItem(sitesOfGame)},
            {...routeObjToItem(versions)},
        ],
    },
    {
        key: 'game-management',
        label: 'Game Management',
        separated: false,
        icon: Icons.Puzzle,
        subMenus: [
            {...routeObjToItem(gamesList)},
            {...routeObjToItem(keywordsList)},
            {...routeObjToItem(languages)},
            {...routeObjToItem(fonts)},
            {...routeObjToItem(libraries)},
        ],
    },
    {
        key: 'rules-management',
        label: 'Rules Management',
        separated: false,
        icon: Icons.Rules,
        subMenus: [
            {...routeObjToItem(rulesEditor)},
            {...routeObjToItem(rulesOfGames)},
            {...routeObjToItem(rulesSharedStyles)},
        ],
    },
    {
        key: 'mailing-management',
        label: 'Emailing Management',
        separated: false,
        icon: Icons.AtSign,
        subMenus: [
            {...routeObjToItem(emailingNewEmail)},
            {...routeObjToItem(emailingMails)},
            {...routeObjToItem(emailingContacts)},
        ],
    },
    {
        key: 'bet-configs-management',
        label: 'Bets Configs Management',
        separated: false,
        icon: Icons.Dollar,
        subMenus: [
            {...routeObjToItem(betConfigsAccounts)},
            {...routeObjToItem(betConfigsSites)},
            {...routeObjToItem(betConfigsNew)},
            {...routeObjToItem(betConfigsList)},
            {...routeObjToItem(betConfigsLimits)},
        ],
    },
    {
        key: 'game-tech-management',
        label: 'GameTech Management',
        separated: false,
        icon: Icons.Layers,
        subMenus: [
            {...routeObjToItem(gameTechUsers)},
            {...routeObjToItem(gameTechLocations)},
            {...routeObjToItem(gameTechNewArea)},
        ],
    },

];

export default menuItems