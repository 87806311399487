import SingletonFactory from "./singleton-factory";
import {isJsonString} from "./js-tools/json-validator";

/**
 * To catch failed services and download as log
 */
class ServiceHistoryProvider {
    constructor(historyLimit = 5) {
        this._historyLimit = historyLimit
        this.history = []
        this._localKey = "SERVICE_HISTORY"

        this.loadLocalData()
    }

    hasHistory() {
        return !!this.history.length
    }

    loadLocalData() {
        const localData = localStorage.getItem(this._localKey)
        if (localData && isJsonString(localData)) {
            this.history = JSON.parse(localData)
        }
    }

    storeLocally() {
        const data = JSON.stringify(this.history)
        localStorage.setItem(this._localKey, data)
    }

    addToHistory(data) {
        if (this.history.length > this._historyLimit) {
            this.history.splice(0, 1)
        }

        this.history.push(data)
        this.storeLocally()
    }

    downloadHistory() {

        const now = new Date();
        const data = JSON.stringify(this.history, null, 2)
        const blob = new Blob([data], {type: "text/plain"});
        const blobURL = URL.createObjectURL(blob);

        const logFileName = `failures_log_`
            + now.toLocaleDateString().replace(/\//g, "-")
            + "_"
            + now.toLocaleTimeString().replace(/\W/g, "-")
            + ".txt"

        const element = document.createElement('a');
        element.setAttribute('href', blobURL);
        element.setAttribute('download', logFileName);

        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }

}

const ServiceHistory = SingletonFactory(ServiceHistoryProvider, 10).getInstance()

window._failures = ServiceHistory.downloadHistory.bind(ServiceHistory)

export default ServiceHistory
