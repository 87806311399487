import React from 'react';
import {styled} from '@mui/material/styles';

const StyledPageContainer = styled("main")((theme) => ({
    overflow: "auto",
    maxHeight: "100vh",
    width: "100%",
    transition:"all .5s ease"
}))

export default function PageContainer({children, isAuthenticated, isSidebarClosed, ...rest}) {

    return (
        <StyledPageContainer sx={{
            pt: isAuthenticated ? 7 : 0,
            marginLeft: (isSidebarClosed || !isAuthenticated) ? 0 : "245px",
            px: 1
        }} {...rest}>
            {children}
        </StyledPageContainer>
    )
}
