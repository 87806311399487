import {createStore, applyMiddleware, compose} from "redux";

import thunk from "redux-thunk";
import thunkMiddleware from "redux-thunk";
import rootReducer from "../reducers";

const initialState = {};
const middleware = [thunk, thunkMiddleware];

/**
 * Redux store generation place
 * @type {Store<unknown, AnyAction> & Store<S & {}, A> & {dispatch: unknown}}
 */
export const reduxStore = createStore(
    rootReducer,
    initialState,
    compose(
        applyMiddleware(...middleware),
        //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )
);
