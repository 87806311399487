import {dataConstants} from "../constants";
import {sharedDataServices} from "../services/shared-data.service";

const initialState = {
    allGames: sharedDataServices.getLocalData("ALL_GAMES") || [],
    languages: sharedDataServices.getLocalData("LANGUAGES") || [],
    countries: sharedDataServices.getLocalData("COUNTRIES") || [],
    currencies: sharedDataServices.getLocalData("CURRENCIES") || [],
    jurisdictions: sharedDataServices.getLocalData("JURISDICTIONS") || [],
    constData: sharedDataServices.getLocalData("CONST_DATA") || {},
    unreadNotifications: []
};

export function data(state = initialState, action) {

    switch (action.type) {
        case dataConstants.SET_DATA:
            const {jurisdictions, countries, currencies, languages} = action.payload
            return {
                ...state,
                jurisdictions,
                countries,
                currencies,
                languages
            };
        case dataConstants.SET_COUNTRIES:
            return {
                ...state,
                countries: action.payload
            };
        case dataConstants.SET_LANGUAGES:
            return {
                ...state,
                languages: action.payload
            };
        case dataConstants.SET_ALL_GAMES:
            return {
                ...state,
                allGames: action.payload
            };
        case dataConstants.SET_CURRENCIES:
            return {
                ...state,
                currencies: action.payload
            };
        case dataConstants.SET_JURISDICTIONS:
            return {
                ...state,
                jurisdictions: action.payload
            };
        case dataConstants.SET_CONST_DATA:
            return {
                ...state,
                constData: action.payload
            };
        case dataConstants.SET_UNREAD_NOTIFICATIONS:
            return {
                ...state,
                unreadNotifications: action.payload
            };
        case dataConstants.ADD_UNREAD_NOTIFICATIONS:
            return {
                ...state,
                unreadNotifications: [action.payload, ...state.unreadNotifications]
            };
        case dataConstants.CLEAR_UNREAD_NOTIFICATIONS:
            return {
                ...state,
                unreadNotifications: state.unreadNotifications
                    .map(n => {
                        n.read = true;

                        return n
                    })
            };
        default:
            return state;
    }
}
