import {combineReducers} from "redux";
import {settings} from "./settings.reducer";
import {auth} from "./auth.reducer";
import {data} from "./data.reducer";

/**
 * Root reducer of redux, it will combine all reducers as one and export it
 *
 * @type {Reducer<any>}
 */
const rootReducer = combineReducers({
    settings,
    auth,
    data
});

export default rootReducer;