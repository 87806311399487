import React from "react";
import {createTheme} from '@mui/material/styles';
import ThemeProvider from "@mui/styles/ThemeProvider";
import {deepmerge} from '@mui/utils';
import allThemes from "../constants/themes.contants";

export function createCustomTheme(themeObj) {
    return createTheme(deepmerge(allThemes.default, themeObj))
}

export const dangerTheme = createTheme({...allThemes.default, ...allThemes.dangerLight})

export function RedTheme({children}) {
    return (
        <ThemeProvider theme={dangerTheme}>
            {children}
        </ThemeProvider>
    )
}
