import axios from "axios";
import {getToken, getOneTimeToken, authService, setOneTimeToken} from "./auth.service";
import {history, isJsonString, reduxStore, toasts} from "../utils";
import {authActions} from "../actions/auth.actions";
import {BroadcastChannel} from "broadcast-channel";
import {ServiceWrapper, HOOKS} from "js-service-wrapper";
import moment from "moment";
import "moment-timezone";
import ServiceHistory from "../utils/service-history";
import BASE_SERVICE_URL from "./get-service-url";

// initialize ServiceWrapper
ServiceWrapper
    .init({
        client: axios,
        queue: true,
        queueLogs: false,
        defaultParallelStatus: false,
    })
    // check the status of request and ErrorCode existence
    .setResolveValidation(res => res.status === 200 && !res.data.ErrorCode)
    .setHook(HOOKS.BEFORE_RESOLVE, res => res.data)
    .setHook(HOOKS.AFTER_SUCCESS, (res, fireConfig = {}) => {

        // show warning toast if requires
        if (res && res.data && res.data.warningText) {
            toasts.warning(res.data.warningText);
        }

        // show success message if asked to
        if (fireConfig.resolveMSG) {
            toasts.success(fireConfig.resolveMSG)
        }

    })
    .setHook(HOOKS.AFTER_FAIL, (err, fireConfig = {}) => {

        if (err && err.data && err.data.ErrorCode)
            toasts.error(`${(err.data.ErrorMessage || fireConfig.rejectMSG)};
            ${err.data.ErrorDetails ? err.data.ErrorDetails + ';' : ""} Code: ${err.data.ErrorCode}`)

        else if (err.message)
            toasts.error(fireConfig.rejectMSG || err.message)

        // add error object to failures history
        let historyData = {
            unix: new Date().getTime(),
            response: err.data || err.message,
        }

        if (err.config) {
            const now = new Date().getTime()
            const {startTime = now} = err.config.meta
            const duration = now - startTime

            historyData = {
                ...historyData,
                method: err.config.method,
                duration,
                url: err.config.url,
                data: err.config.data && isJsonString(err.config.data)
                    ? JSON.parse(err.config.data)
                    : err.config.data,
                params: err.config.params && isJsonString(err.config.params)
                    ? JSON.parse(err.config.params)
                    : err.config.params,
            }
        }

        ServiceHistory.addToHistory(historyData)
    })

    // Check possible date fields for each request
    // and convert it to the GMT timezone
    .setHook(HOOKS.UPDATE_SERVICE_CONFIG, reqObj => {
        const possibleDateFields = ["StartDate", "EndDate", "PaymentDateTime", "startdate", "enddate", "Deadline", "deadline", "UserId", "SiteId", "start_date", "finish_date"]

        // check if data field or params field passed
        // and assign it to target.
        let target = reqObj.data ? "data" : "params";

        // check the existence of target in request object
        if (reqObj[target]) {
            // loop over passed keys
            Object.keys(reqObj[target]).forEach(field => {
                // check if the current field is a date field
                if (possibleDateFields.includes(field)) {
                    let date = reqObj[target][field];

                    // convert date string to time GMT
                    let updatedDate = moment(date).tz("GMT").format();

                    // convert unix date to GMT
                    if (typeof date === "number")
                        updatedDate = moment.unix(date).tz("GMT").unix();

                    // update field with updated date
                    reqObj[target][field] = updatedDate;
                }
            })
        }

        return reqObj;
    })


//  update next-token cross tabs and windows
const nextTokenUpdateChannel = new BroadcastChannel('next-token-update-channel');
nextTokenUpdateChannel.addEventListener("message", function (token) {
    // update next token in redux and localStorage
    reduxStore.dispatch(authActions.updateOneTimeToken(token))
})

// routes that x-next-token is not required
// and should pass from x-next-token adding and catching progress
const tokenFreeRoutes = [
    `${BASE_SERVICE_URL}/login`,
    `${BASE_SERVICE_URL}/logout`,
    `${BASE_SERVICE_URL}/forgotten`,
    `${BASE_SERVICE_URL}/const`,
];

axios.defaults.headers.common['token'] = getToken();
axios.defaults.headers.common['Content-Type'] = 'application/json';

// this lines will handle the token less and not authed requests of axios.
axios.interceptors.response.use(function (response) {

    // check if the response is a free token response
    if (tokenFreeRoutes.includes(response.config.url))
        return response;

    const newOneTimeToken = (response.headers["x-next-token"] || "").substring(0, 10);
    // ask to update token in other open tabs and windows
    reduxStore.dispatch(authActions.updateOneTimeToken(newOneTimeToken))
    nextTokenUpdateChannel.postMessage(newOneTimeToken);

    return response;
}, (error) => {
    const url = error.config.url;
    let action = "Unknown";
    if (url) {
        action = url
            .replace(BASE_SERVICE_URL + "/", "")
            .replace("/", "-")
    }

    if (!error.response || ~error.message.indexOf("401")) { // One-Time-Token authentication failed
        // this is just for memorise the last url that visited and use it in login attempt
        if (!window.LAST_VISITED_URL) {
            window.LAST_VISITED_URL = window.location.href.split(window.location.host)[1];
            reduxStore.dispatch(authActions.logout())
            history.push('/login')
        }
    }
    return Promise.reject(error);
});
// end

axios.interceptors.request.use(request => {

    request.meta = {startTime: new Date().getTime()}
    // check if the request is a free token request
    if (tokenFreeRoutes.includes(request.url))
        return request;

    // Modify request here
    let oneTimeToken = reduxStore.getState().auth.oneTimeToken
    if (!oneTimeToken)
        oneTimeToken = getOneTimeToken()

    request.headers['X-One-Time-Token'] = oneTimeToken || "fake-token";
    return request
});
