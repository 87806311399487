import React, {useEffect} from 'react';
import {connect} from "react-redux";
import {Route, Switch, withRouter} from "react-router-dom";
import "./services/index";
import {authActions} from "./actions/auth.actions";
import {history, newRoutes, toasts} from "./utils";
import MainHeader from "./components/layout/main-header";
import MainSidebar from "./components/layout/main-sidebar";
import {settingsActions} from "./actions/settings.actions";
import PrivateRouteHandler from "./components/layout/private-route-handler";
import {authService} from "./services/auth.service";
import NotFound404 from "./components/not-found-404";
import useDataInit from "./hooks/data-hooks/data.hook";
import ScrollTop from "./components/scroll-top";
import useNotificationsHandle from "./hooks/use-notifications.hook";
import NotificationPermissionCard from "./components/notification-card";
import Footer from "./components/footer";
import {Paper} from "@mui/material";
import Theming from "./theming";
import PageContainer from "./components/layout/page-container";
import StyledToastContainer from "./components/styled-toast-container";

function App(props) {
    const {mainSidebarCloseStatus, isAuthenticated} = props

    // load languages, currencies and
    // countries and put in redux state
    useDataInit()

    useEffect(() => {
        const {dispatch} = props;

        // update auth status
        dispatch(authActions.setLoggedIn())

        // set main sidebar close status
        const lastSidebarStatus = localStorage.getItem("MAIN_SIDEBAR_CLOSE");
        dispatch(settingsActions.toggleMainMenu(lastSidebarStatus === "true"))
    }, [])

    useNotificationsHandle()

    useEffect(() => {
        if (authService.forcePasswordChange()) {
            history.push(newRoutes.profileEdit.path)
        }
    }, [props.admin])

    const renderSwitch = () => (
        <Switch>
            {Object.values(newRoutes).map((route, key) => {
                return <PrivateRouteHandler {...route} path={route.path} key={key}
                                            exact={route.exact} component={route.component}/>;
            })}
            <Route component={NotFound404}/>

        </Switch>
    )

    return (
        <Theming>
            <Paper sx={{
                display: "flex",
                minHeight: "100vh",
                position: "relative",
            }}>

                {isAuthenticated && <MainHeader isSidebarClosed={mainSidebarCloseStatus}/>}

                {isAuthenticated && <MainSidebar/>}

                <PageContainer isAuthenticated={isAuthenticated} isSidebarClosed={mainSidebarCloseStatus}
                               id={"main-content"}>

                    {renderSwitch()}

                    {isAuthenticated && <ScrollTop/>}

                    <Footer/>
                </PageContainer>

                <StyledToastContainer/>
                <NotificationPermissionCard/>
            </Paper>
        </Theming>
    );
}

function mapStateToProps(state) {

    return {
        admin: state.auth.admin,
        isAuthenticated: state.auth.isAuthenticated,
        mainSidebarCloseStatus: state.settings.main_sidebar_close,
        layout: state.settings.layout
    }

}

export default connect(mapStateToProps)(withRouter(App));
