import React, {useEffect, useState} from 'react';
import NotifyService from "../utils/notification-service";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import CardActions from "@mui/material/CardActions/CardActions";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card/Card";
import { styled } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import {useSelector} from "react-redux";

const PREFIX = 'notification-card';

const classes = {
    root: `${PREFIX}-root`,
    noticeP: `${PREFIX}-noticeP`
};

const StyledCard = styled(Card)((
    {
        theme
    }
) => ({
    [`&.${classes.root}`]: {
        position: "fixed",
        top: "2%",
        left: "2%",
        zIndex: 99999,
        width: 430,
        maxWidth: "96%",
        display: 'block',
        "& .MuiCardActions-root": {
            justifyContent: "flex-end"
        }
    },

    [`& .${classes.noticeP}`]: {
        marginTop: "1rem"
    }
}));

const PERMISSION_TS_KEY = "notification_permission_ts"

export default function NotificationPermissionCard() {



    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)

    const [visibility, setVisibility] = useState(false);

    useEffect(() => {
        if (isAuthenticated)
            checkVisibility()
    }, []);

    useEffect(() => {
        checkVisibility()
    }, [isAuthenticated]);

    function checkVisibility() {

        if (!isAuthenticated) {
            setVisibility(false)
            return
        }

        const prePermissionDisallowTS = localStorage.getItem(PERMISSION_TS_KEY);
        if (prePermissionDisallowTS) {
            const momentTS = new Date().getTime();
            const _24TS = 24 * 60 * 60 * 1000;

            if (momentTS - +prePermissionDisallowTS > _24TS && !NotifyService.isSubscribed()) {
                setVisibility(true);
                localStorage.removeItem(PERMISSION_TS_KEY);
            }
        } else if (!NotifyService.isSubscribed()) {
            setVisibility(true);
        }
    }

    function allowPermission() {
        NotifyService.requestPermission();
        setVisibility(false);
    }

    function setKeyLaterPermission() {
        const momentTS = new Date().getTime();
        localStorage.setItem(PERMISSION_TS_KEY, momentTS.toString());

        setVisibility(false);
    }

    if (!visibility)
        return null

    return (
        <StyledCard className={classes.root}>
            <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                    Notification Permission
                </Typography>
                <Typography variant="body1" color="textSecondary" component="p">
                    You need to allow notifications to receive the latest status of actions.
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p" className={classes.noticeP}>
                    <strong>Notice: </strong>Make sure that your permission is not blocked before.
                </Typography>
            </CardContent>
            <CardActions>
                <Button size="small" color="primary" onClick={setKeyLaterPermission}>
                    Maybe Later
                </Button>
                <Button size="small" color="primary"
                        variant="contained"
                        onClick={allowPermission}>
                    Allow
                </Button>
            </CardActions>
        </StyledCard>
    );
}
