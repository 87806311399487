import React from 'react';
import cx from "classnames";
import Typography from "@mui/material/Typography";
import {styled} from '@mui/material/styles';
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {alpha, IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import {history} from "../utils";
import parseNotificationData from "../utils/parse-notification-data";
import {BiCheckDouble} from "react-icons/bi";

const PREFIX = 'NotificationsPopup';

const classes = {
    root: `${PREFIX}-root`,
    inline: `${PREFIX}-inline`,
    head: `${PREFIX}-head`,
    title: `${PREFIX}-title`,
    markAsRead: `${PREFIX}-markAsRead`,
    notifyItem: `${PREFIX}-notifyItem`,
    newNotifyItem: `${PREFIX}-newNotifyItem`,
    notificationsList: `${PREFIX}-notificationsList`,
    noNotifyAlert: `${PREFIX}-noNotifyAlert`
};

const StyledPopover = styled(Popover)((
    {
        theme
    }
) => ({
    [`& .${classes.root}`]: {
        maxWidth: "468px"
    },

    [`& .${classes.inline}`]: {
        whiteSpace: "wrap",
        display: "inline-block"
    },

    [`& .${classes.head}`]: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        margin: theme.spacing(.7),
        marginBottom: theme.spacing(0),
        minWidth: "320px"
    },

    [`& .${classes.title}`]: {
        fontSize: "14px",
        fontWeight: "500",
        opacity: 0.4
    },

    [`& .${classes.markAsRead}`]: {
        fontWeight: "500",
        color: theme.palette.primary.main,
        cursor: "pointer",
        textTransform: "initial"
    },

    [`& .${classes.notifyItem}`]: {
        marginBottom: theme.spacing(0.2),
    },

    [`& .${classes.newNotifyItem}`]: {
        backgroundColor: alpha(theme.palette.secondary.light, 0.1)
    },

    [`& .${classes.notificationsList}`]: {
        maxHeight: "33vh",
        overflowY: "auto",
        margin: "0"
    },

    [`& .${classes.noNotifyAlert}`]: {
        textAlign: "center",
        margin: theme.spacing(1),
        marginTop: theme.spacing(.2),
        opacity: 0.6
    }
}));

function NotificationsPopup({notifications, onRead, anchorEl, onClose}) {


    const renderNotifyItem = (notify, key) => {

        const notifyData = parseNotificationData(notify);

        if (!notifyData) {
            console.warn(`"${notify.Type}" not added as notification type`);
            return null
        }

        return (
            <ListItem
                className={cx(classes.notifyItem, !notifyData.Read && classes.newNotifyItem)}
                key={key}
                onClick={handleNotifyClick.bind(this, notifyData)}
                button dense>
                <ListItemText
                    primary={notifyData.title}
                    secondary={
                        <Typography
                            component="span"
                            variant="body2"
                            className={classes.inline}
                            color="textSecondary">
                            {notifyData.body}
                        </Typography>
                    }/>
            </ListItem>)
    }

    const handleClose = () => {
        onClose && typeof onClose && onClose();
    };

    const handleMarkAsRead = (id = null) => {
        onRead && typeof onRead && onRead(id);
    };

    const handleViewAllBtnClick = () => {
        history.push("/notifications");
        handleClose();
    }

    const handleNotifyClick = notify => {
        if (notify.targetURL)
            history.push(notify.targetURL);

        if (!notify.Read)
            handleMarkAsRead(notify.Id);

        handleClose();
    }

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <StyledPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <div className={classes.root}>
                <div className={classes.head}>
                    <Typography className={classes.title}>Notifications</Typography>

                    <IconButton className={classes.markAsRead}
                                onClick={handleMarkAsRead.bind(this, null)}>
                        <BiCheckDouble/>
                    </IconButton>

                </div>

                <List className={classes.notificationsList}>
                    {notifications.map((item, key) => renderNotifyItem(item, key))}
                </List>

                {(!notifications || !notifications.length) &&
                <Typography variant="body2"
                            className={classes.noNotifyAlert}>
                    No new notifications
                </Typography>}

                <Button fullWidth size="small" onClick={handleViewAllBtnClick}
                        sx={{py: .75}}>
                    View All
                </Button>

            </div>
        </StyledPopover>
    );
}

export default NotificationsPopup
