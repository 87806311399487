import React, {useState, useMemo, useEffect} from "react";
import {generateGameSearchItem, generalSearchItem} from "../utils/general-search";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {Box, Button, List, ListItemButton, ListItemIcon, ListItemText, TextField} from "@mui/material";
import Icons from "./icons";
import InputAdornment from "@mui/material/InputAdornment";
import {styled} from "@mui/styles";
import NoContentPlaceholder from "./no-content-placeholder";
import {history, newRoutes} from "../utils";
import {useSelector} from "react-redux";

const checkTrendForItem = (item, trend) => {
    return item.title.toLowerCase().includes(trend.toLowerCase()) ||
        item.keywords.filter(k => k.toLowerCase().includes(trend.toLowerCase())).length > 0
}

const StylesListItem = styled(ListItemButton)(({theme}) => ({
    padding: ".75rem 1rem",
    border: "1px solid transparent",
    borderBottom: "1px solid " + theme.palette.background.paperDark,
    "&:hover": {
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
    },
    "&:focus": {
        backgroundColor: theme.palette.background.paper,
        borderRadius: theme.shape.borderRadius,
        borderColor: theme.palette.primary.light,
        color: theme.palette.primary.main,
    },
    "& .MuiTypography-root": {
        fontSize: ".9rem"
    },
    "& .MuiListItemText-secondary": {
        fontSize: ".75rem",
        marginTop: ".2rem"
    }
}))

function useGenerateGameItems() {
    const games = useSelector(s => s.data.allGames)

    return useMemo(() => {
        const res = [...generalSearchItem]
        games.forEach(g => res.push(generateGameSearchItem(g)))
        return res
    }, [games, generalSearchItem])
}

function useFilterItems(allItems, search) {
    return useMemo(() => {
        return JSON.parse(JSON.stringify(allItems)).filter((item) => {
            const itemContains = checkTrendForItem(item, search)
            item.childItems = item.childItems.filter(child => {
                return checkTrendForItem(child, search)
            })
            return itemContains || !!item.childItems.length
        }).slice(0, 10)
    }, [allItems, search])
}

function GeneralSearch({open, onClose}) {

    const [search, setSearch] = useState("")

    useEffect(() => {
        if (open) {
            setSearch("")
        }
    }, [open])

    const allItems = useGenerateGameItems()

    const filteredSearch = useFilterItems(allItems, search)

    const handleClose = () => onClose && onClose()

    const handleItemClick = (item) => {
        history.push(item.path)
        handleClose();
    }

    return (
        <Dialog
            open={open}
            fullWidth={true}
            onClose={handleClose}
            fullScreen={false}
        >
            <DialogTitle id="max-width-dialog-title" component={"div"} sx={{p: 0}}>
                <TextField
                    fullWidth
                    size="large"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    sx={{
                        py: 1,
                        px: 1.5,
                        fontSize: 18,
                        "& .MuiInputBase-root:before": {
                            display: "none"
                        },
                        "& .MuiInputBase-root:after": {
                            display: "none"
                        }
                    }}
                    autoFocus
                    autoComplete={"off"}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start" sx={{fontSize: 24}}>
                                <Icons.Detail/>
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="end">
                                <Button size={"small"} onClick={handleClose}>esc</Button>
                            </InputAdornment>
                        ),
                    }}
                    variant="standard"
                />
            </DialogTitle>
            <DialogContent dividers sx={{p: 1}}>
                {!!filteredSearch.length &&
                <List>
                    {filteredSearch.map(item => (
                        <React.Fragment key={item.path}>
                            <StylesListItem divider onClick={() => handleItemClick(item)}>
                                <ListItemText primary={item.title}
                                              secondary={item.description}/>
                            </StylesListItem>
                            {!!item.childItems?.length &&
                            <List component="div" disablePadding key={item.path + "child"}>
                                {item.childItems.map(child => (
                                    <StylesListItem key={child.path} divider onClick={() => handleItemClick(child)}>
                                        <ListItemIcon sx={{justifyContent: "center"}}>
                                            <Icons.ChildItem/>
                                        </ListItemIcon>
                                        <ListItemText primary={child.title}
                                                      secondary={child.description}/>
                                    </StylesListItem>
                                ))}
                            </List>}
                        </React.Fragment>
                    ))}
                </List>}
                {!filteredSearch.length &&
                <NoContentPlaceholder sx={{display: "flex"}}>
                    No result found for <Box component={"span"}
                                             sx={{
                                                 ml: .25,
                                                 fontWeight: 700,
                                                 display: "inline-block",
                                                 maxWidth: 150,
                                                 textOverflow: "ellipsis",
                                                 overflow: "hidden"
                                             }}>{search}</Box>.
                </NoContentPlaceholder>}
            </DialogContent>
        </Dialog>
    )
}

export default GeneralSearch