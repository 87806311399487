/**
 * this file contains the ACTION TYPES of the Redux
 */
export const settingsConstants = {
    ACTIONS_MENU_OPEN: 'SETTINGS_ACTIONS_MENU_OPEN',
    MAIN_SIDEBAR_CLOSE: 'SETTINGS_MAIN_SIDEBAR_CLOSE',
    SET_CURRENT_ROUTE_OBJ: 'SETTINGS_SET_CURRENT_ROUTE_OBJ',
    SET_DARK_MODE: 'LAYOUT_SET_DARK_MODE',
};

export const authConstants = {
    LOGIN_REQUEST: 'AUTH_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'AUTH_LOGIN_SUCCESS',
    SET_LOGGED_IN_STATUS: 'AUTH_SET_LOGGED_IN_STATUS',
    UPDATE_ONE_TIME_TOKEN: 'AUTH_UPDATE_ONE_TIME_TOKEN',
    UPDATE_ADMIN_STATE: 'AUTH_UPDATE_ADMIN_STATE',
    LOGOUT: 'AUTH_LOGOUT',
};

export const dataConstants = {
    SET_DATA: 'DATA_SET_DATA',
    SET_ALL_GAMES: 'DATA_SET_ALL_GAMES',
    SET_LANGUAGES: 'DATA_SET_LANGUAGES',
    SET_CURRENCIES: 'DATA_SET_CURRENCIES',
    SET_COUNTRIES: 'DATA_SET_COUNTRIES',
    SET_JURISDICTIONS: 'DATA_SET_JURISDICTIONS',
    SET_CONST_DATA: 'DATA_SET_CONST_DATA',
    ADD_UNREAD_NOTIFICATIONS: 'DATA_ADD_UNREAD_NOTIFICATIONS',
    SET_UNREAD_NOTIFICATIONS: 'DATA_SET_UNREAD_NOTIFICATIONS',
    CLEAR_UNREAD_NOTIFICATIONS: 'DATA_CLEAR_UNREAD_NOTIFICATIONS',
};
