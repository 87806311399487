import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {Box, Breadcrumbs, Link as LinkElm, Stack, Typography} from "@mui/material";
import {Link, useLocation, useRouteMatch} from "react-router-dom";
import usePageTitleChange from "../../hooks/use-page-title-change-hook";
import {newRoutes} from "../../utils";
import {settingsActions} from "../../actions/settings.actions";

const BCLink = ({to, title}) => (
    <Link to={to}>
        <LinkElm underline="hover" color="text.secondary" variant={"body2"} component={"span"}>
            {title}
        </LinkElm>
    </Link>
)

const firstBreadCrumb = ["Dashboard", "/"]

export default function PageMeta({actions = null, title, parents = [], breadCrumbTitle = null}) {
    const lastVisitedRoute = useSelector(state => state.settings.lastVisitedRoute);
    let lastBreadCrumbTitle = breadCrumbTitle || lastVisitedRoute.title

    const {path} = useRouteMatch()
    const dispatch = useDispatch();

    // set current route object
    useEffect(() => {
        Object.values(newRoutes).map(route => {
            if (route.path === path) {
                dispatch(settingsActions.setCurrentRouteObj(route))
            }
            return route
        })
    }, [path])

    usePageTitleChange(title, title)

    const isDashboard = lastVisitedRoute.title === "Dashboard"

    const Actions = actions || React.Fragment

    // generate breadcrumbs
    let breadCrumbs = [...parents]
    if (!isDashboard)
        breadCrumbs.unshift(firstBreadCrumb)

    return (
        <Box sx={{px: 1, mb: 2, display: "flex", justifyContent: "space-between"}}>
            <Box>
                <Typography variant={"h4"}>{lastVisitedRoute.title}</Typography>
                <Stack spacing={2}>
                    <Breadcrumbs separator="›" aria-label="breadcrumb">
                        {breadCrumbs.map(([title, to], k) => (
                            <BCLink title={title} to={to} key={k}/>
                        ))}

                        <Typography color="text.hint" variant={"body2"}
                                    component={"span"}>
                            {lastBreadCrumbTitle?.length > 10 && !isDashboard
                                ? lastBreadCrumbTitle.substr(0, 10).trim() + "..."
                                : lastBreadCrumbTitle}
                        </Typography>
                    </Breadcrumbs>
                </Stack>
            </Box>
            {!!actions &&
            <Box><Actions/></Box>}
        </Box>
    )

}
