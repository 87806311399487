import {authConstants} from "../constants/redux.constants";
import {authService} from "../services/auth.service";

// const defaultRoles = {
//     languages: ["read", "write", "delete"],
//     keywords: ["read"],
//     games: ["read", "write"],
//     game_keywords: ["read", "create"],
//     fonts: ["read", "delete"],
// }

const initialState = {
    roles: {},
    isAuthenticated: false,
    admin: null,
    oneTimeToken: null,
};

export function auth(state = initialState, action) {

    switch (action.type) {
        case authConstants.SET_LOGGED_IN_STATUS:
            if (!action.payload)
                action.payload = {}

            return {
                ...state,
                isAuthenticated: action.payload.isAuthenticated,
                admin: action.payload.admin,
                roles: action.payload.roles,
            };

        case authConstants.LOGIN_REQUEST:
            return {
                ...state,
                isAuthenticated: false,
            };

        case authConstants.LOGIN_SUCCESS:

            return {
                ...state,
                isAuthenticated: true,
                admin: action.payload.admin,
                oneTimeToken: action.payload.oneTimeToken,
                roles: action.payload.roles
            };

        case authConstants.UPDATE_ADMIN_STATE:
            return {
                ...state,
                admin: action.payload
            };

        case authConstants.UPDATE_ONE_TIME_TOKEN:
            return {
                ...state,
                oneTimeToken: action.payload
            };
        case authConstants.LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                admin: null,
                roles: null
            };
        default:
            return state;
    }
}
