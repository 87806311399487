import React from 'react';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {version} from "../../package.json"
import FailuresLogDownloadBtn from "./failures-log-download-button";

const PREFIX = 'Footer';

const classes = {
    footer: `${PREFIX}-footer`
};

const Root = styled('footer')({
    [`&.${classes.footer}`]: {
        marginTop: ".5rem",
        textAlign: "center",
        fontSize: 12,
        position: "sticky",
        top: "99vh",
        paddingBottom:".5rem"
    }
});

function Footer(props) {

    return (
        <Root className={classes.footer}>
            Backoffice v{version} | <strong>© Spinmatic Entertainment</strong>

            <FailuresLogDownloadBtn/>
        </Root>
    );
}

export default Footer;
