import {useEffect, useState} from "react";
import NotifyService from "../utils/notification-service";
import {notificationsService} from "../services/notifications.service";
import MSG from "../constants/messages.constants";
import {useDispatch, useSelector} from "react-redux";
import {fetchNotifications, useNotificationsFetch} from "./data-hooks/notifications-management.hook";
import {dataActions} from "../actions/data.actions";

function useNotifications() {

    const dispatch = useDispatch();
    const admin = useSelector(state => state.auth.admin);

    const [unreadNotifications, setUnreadNotifications] = useNotificationsFetch({orderBy: "!unix", status: "unread"})

    useEffect(() => {
        dispatch(dataActions.setUnreadNotifications(unreadNotifications))
    }, [unreadNotifications])

    // send subscription to server if user is authed
    useEffect(() => {

        if (!admin)
            return;

        NotifyService.onSubscription = (sub) => {
            if (!sub || !sub.endpoint) {
                console.error("Notification register failed")
                return
            }

            const client = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)
                ? "mobile"
                : "desktop";

            sub = JSON.parse(JSON.stringify(sub))
            notificationsService
                .pushNotificationRegister(sub.endpoint, sub.keys.auth, sub.keys.p256dh, client)
                .fire({rejectMSG: MSG.NOTIFICATION_REGISTER_FAILED})
        }
    }, [admin])
}

export default useNotifications;
