import React, {useEffect, useState} from "react"
import WAITING_FOR from "../../constants/waiting-for.constants";
import {notificationsService} from "../../services/notifications.service";
import {useSelector} from "react-redux";
import {authService} from "../../services/auth.service";

/**
 * Hook to fetch notifications
 * @returns {Array}
 */
function useNotificationsFetch(params) {
    const admin = useSelector(state => state.auth.admin);

    const [notifications, setNotifications] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [waitingFor, setWaitingFor] = useState(null)

    useEffect(() => {
        if (!admin || !!waitingFor || authService.forcePasswordChange()) return;

        setWaitingFor(WAITING_FOR.DATA)
        fetchNotifications(params)
            .then(res => {
                setNotifications(res[0])
                setTotalCount(res[1])
            })
            .finally(() => setWaitingFor(null))

    }, [admin])

    return [notifications, setNotifications, totalCount, setTotalCount, waitingFor, setWaitingFor]
}

/**
 * async func to handle data fetch
 * @returns {Promise<Array|*>}
 */
async function fetchNotifications(params = {}) {

    const notificationsRes = await notificationsService.getAllNotifications(params)
        .fire()

    return [notificationsRes.Notifications, notificationsRes.TotalCount]
}


/**
 * Hook to fetch subs status
 * @returns {Array}
 */
function useSubscriptionsFetch(params) {
    const [allSubs, setAllSubs] = useState([])
    const [waitingFor, setWaitingFor] = useState(WAITING_FOR.DATA)

    useEffect(() => {
        (async () => {
            const res = await fetchSubscriptions(params)
            setAllSubs(res)
            setWaitingFor(null)
        })();
    }, [])

    return [allSubs, setAllSubs, waitingFor, setWaitingFor]
}

async function fetchSubscriptions(params = {}) {

    return await notificationsService.getSubscriptions(params)
        .fire()
}

function usePermissionsFetch(params = {}) {
    const [permissions, setPermissions] = useState([])
    const [totalCount, setTotalCount] = useState(0)
    const [waitingFor, setWaitingFor] = useState(WAITING_FOR.DATA)

    useEffect(() => {
        (async () => {
            try {
                const res = await fetchPermissions(params)
                setPermissions(res[0])
                setTotalCount(res[1])
            } finally {
                setWaitingFor(null)
            }
        })();
    }, [])

    return [permissions, setPermissions, totalCount, setTotalCount, waitingFor, setWaitingFor]

}

async function fetchPermissions(params = {}) {

    const res = await notificationsService.getPermissions(params)
        .fire()

    return [res.Permissions, res.TotalCount]
}


export {
    useNotificationsFetch, fetchNotifications,
    fetchSubscriptions, useSubscriptionsFetch,
    usePermissionsFetch, fetchPermissions
}
