const WAITING_FOR = {
    DATA: "DATA",
    CONFIRM_MODAL_ACTION: "CONFIRM_MODAL_ACTION",
    KEYWORDS_FETCH: "KEYWORDS_FETCH",
    TAGS_FETCH: "TAGS_FETCH",
    USERS_FETCH: "USERS_FETCH",
    REQUEST_DONE: "REQUEST_DONE",
    USER_APPROVE_REQUEST_DONE: "USER_APPROVE_REQUEST_DONE",
    GAMES_FETCH: "GAMES_FETCH",
    FONTS_FETCH: "FONTS_FETCH",
    RULE_TEMPLATE_LOADING: "RULE_TEMPLATE_LOADING",
    RULE_VERSION_LOADING: "RULE_VERSION_LOADING",
    LIBS_FETCH: "LIBS_FETCH",
    NOTIFY_SUBSCRIPTION: "NOTIFY_SUBSCRIPTION",
    MARK_AS_READ: "MARK_AS_READ",
    LANGUAGES_FETCH: "LANGUAGES_FETCH",
    RELEASES_FETCH: "RELEASES_FETCH",
    UPLOADING: "UPLOADING",
    SITES_FETCH: "SITES_FETCH",
}
export default WAITING_FOR;
