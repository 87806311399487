import * as browserHistory from 'history'
import NProgress from "nprogress";
import {newRoutes} from "./routes";
import {settingsActions} from "../actions/settings.actions";
import {reduxStore} from "./redux-store";
import {authService} from "../services/auth.service";
import {toasts} from "./js-tools/toasts";
import MSG from "../constants/messages.constants";
import config from "../config.json";

/**
 * make instance of browserHistory to handle routes
 * @type {createBrowserHistory}
 */
const createHistory = browserHistory.createBrowserHistory
const history = createHistory({basename: config.BASENAME});

// override push method
const orgPush = history.push;
history.push = (path, event) => {
    if (event && event.ctrlKey) {
        window.open(path)
    } else {
        orgPush(path)
    }
}

// change document title on route change and
// handle the NProgress effect for equal routes
history.listen((location, action) => {
    const {settings: {lastVisitedRoute},} = reduxStore.getState()

    if (lastVisitedRoute.path !== location.pathname) {
        // start page progress on route change
        NProgress.start()

        if (window.innerWidth < 769) {
            reduxStore.dispatch(settingsActions.toggleMainMenu(true))
        }

    }

    setTimeout(() => {
        if (authService.forcePasswordChange() && location.pathname !== newRoutes.profileEdit.path) {
            history.push(newRoutes.profileEdit.path);
            toasts.info(MSG.FORCE_PASSWORD_RESET_INFO)
        }
    }, 0)

})
export {history};
