import React from 'react';
import {
    AiOutlineClear,
    AiOutlineDelete,
    AiOutlineDollar,
    AiOutlineEdit, AiOutlineFunction,
    AiOutlineLink,
    AiOutlineLock, AiOutlineSetting,
    AiOutlineUser,
    BiCategoryAlt,
    BiFileFind, BsHandThumbsUp,
    FiAtSign, FiDatabase,
    FiLayers,
    FiUsers, HiOutlineTranslate,
    IoExtensionPuzzleOutline, IoLibraryOutline,
    IoMdPaper,
    IoRocketOutline, MdOutlineAttachFile,
    MdOutlineSpaceDashboard,
    VscTypeHierarchySub,
    AiOutlineBell, IoSunnyOutline,
    WiMoonAltWaningGibbous3, AiOutlineDownload,
    MdLowPriority, MdOutlineCollections, BsCheck2,
    AiOutlineClose, MdOutlineTextFormat,
    MdOutlineInsertDriveFile, MdOutlinePreview,
    AiOutlineSearch,
    AiOutlineGlobal,
    BsSpeedometer2,
    AiOutlineCopy,
    BiBookContent, IoCodeDownloadOutline, MdOutlineCancelPresentation,
    ImNewTab, AiOutlineCloudUpload, MdSubdirectoryArrowRight, RiCustomerService2Line,
    AiOutlineCode,BsBarChartLine,FaArrowRight
} from "react-icons/all";
import {MdClear, MdOutlineViewColumn} from "react-icons/md";
import {ExpandLess, ExpandMore} from "@mui/icons-material";

const Icons = {
    Dashboard: MdOutlineSpaceDashboard,
    Users: FiUsers,
    User: AiOutlineUser,
    Hierarchy: VscTypeHierarchySub,
    Rocket: IoRocketOutline,
    Puzzle: IoExtensionPuzzleOutline,
    Rules: IoMdPaper,
    AtSign: FiAtSign,
    Dollar: AiOutlineDollar,
    Layers: FiLayers,
    Delete: AiOutlineDelete,
    Clear: MdClear,
    Columns: MdOutlineViewColumn,
    Edit: AiOutlineEdit,
    Lock: AiOutlineLock,
    Type: BiCategoryAlt,
    Find: BiFileFind,
    Link: AiOutlineLink,
    Libraries: IoLibraryOutline,
    Translate: HiOutlineTranslate,
    Keywords: HiOutlineTranslate,
    Database: FiDatabase,
    Attach: MdOutlineAttachFile,
    Math: AiOutlineFunction,
    Settings: AiOutlineSetting,
    Approve: BsHandThumbsUp,
    Purge: AiOutlineClear,
    ExpandLess: ExpandLess,
    ExpandMore: ExpandMore,
    Notifications: AiOutlineBell,
    LightMode: IoSunnyOutline,
    DarkMode: WiMoonAltWaningGibbous3,
    Download: AiOutlineDownload,
    Priority: MdLowPriority,
    Collections: MdOutlineCollections,
    Check: BsCheck2,
    Close: AiOutlineClose,
    TextFormat: MdOutlineTextFormat,
    InsertDrive: MdOutlineInsertDriveFile,
    PageView: MdOutlinePreview,
    Sites: AiOutlineGlobal,
    Detail: AiOutlineSearch,
    Limits: BsSpeedometer2,
    Copy: AiOutlineCopy,
    ViewContent: BiBookContent,
    ErrorDownload: IoCodeDownloadOutline,
    Failed: MdOutlineCancelPresentation,
    OpenNewTab: ImNewTab,
    LoadEditor: AiOutlineCloudUpload,
    ChildItem: MdSubdirectoryArrowRight,
    Customer: RiCustomerService2Line,
    BackendConfig: AiOutlineCode,
    Statistic: BsBarChartLine,
    ArrowRight: FaArrowRight
}

export default Icons

