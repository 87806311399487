/**
 * A component to handle private and public route.
 * if authentication is false, this will redirect all private components to /auth page
 */

import React, {lazy, useEffect} from 'react';
import {Route, Redirect} from 'react-router-dom';
import {authService} from "../../services/auth.service";
import NProgress from 'nprogress'
import {connect} from "react-redux";
import {isValidJSON} from "../../utils";

function PrivateRouteHandler(props) {
    const {component: Component, ...rest} = props

    useEffect(() => {
        // stop router progress bar
        NProgress.done()
    }, [])

    function getRouteCache(id) {
        let cacheStr = localStorage.getItem(id);
        if (cacheStr && isValidJSON(cacheStr)) {
            return JSON.parse(cacheStr)
        }

        return {}
    }

    function setRouteCache(pageID, data, clearPrev = false) {
        if (!data || Object.prototype.toString.call(data) !== "[object Object]") {
            console.log("Route cache data must be an object");
            return false;
        }

        let prevCache = {};

        // remove prev cache if clearPrev is true
        if (clearPrev) {
            clearRouteCache(pageID);

        } else {
            // load prev cache
            prevCache = getRouteCache(pageID);
        }

        const dataStr = JSON.stringify({...prevCache, ...data});

        // update data
        localStorage.setItem(pageID, dataStr)

        return true;
    }

    function clearRouteCache(pageID) {
        localStorage.removeItem(pageID)
    }

    function generateStateCacheProps(routeProps) {
        if (!props.cache) return;

        const pageID = `route_cache_${routeProps.match.path}`

        return {
            routeCache: getRouteCache(pageID),
            setRouteCache: setRouteCache.bind(this, pageID),
            clearRouteCache: clearRouteCache.bind(this, pageID)
        }
    }

    // render method for Route to calc status of the routes
    const renderRoutes = (routeProps) => {

        let cacheHandlerProps = generateStateCacheProps(routeProps)

        const {
            component, private: isPrivate,
            componentPath,
            action, redirectOnAuth, ...rest
        } = props

        let Component = component;

        if (componentPath)
            Component = lazy(() => import("../../../" + componentPath))

        const isAuthenticated = authService.isAuthenticated();

        if (isAuthenticated) {
            if (action && isPrivate && !authService.isPermittedAction(action)) {
                return <Redirect to={{pathname: `/not-allowed/${action}`, state: {from: routeProps.location}}}/>
            } else if (isPrivate || (!isPrivate && !redirectOnAuth)) {
                return <React.Suspense fallback={null}>
                    <Component data={rest.data} {...routeProps} {...cacheHandlerProps}/>
                </React.Suspense>
            } else if (!isPrivate && redirectOnAuth)
                return <Redirect to={{pathname: '/', state: {from: routeProps.location}}}/>

        } else if (!isAuthenticated) {
            if (isPrivate) {
                return <Redirect to={{pathname: '/login', state: {from: routeProps.location}}}/>

            } else if (!isPrivate) {
                return <React.Suspense fallback={null}>
                    <Component data={rest.data} {...routeProps} />
                </React.Suspense>
            }
        }

    }

    return (
        <Route {...rest} render={renderRoutes}/>
    )
}

function mapStateToProps(state) {
    return {
        roles: state.auth.roles,
        isAuthenticated: state.auth.isAuthenticated,
    }
}

export default connect(mapStateToProps)(PrivateRouteHandler)
