import React from 'react';
import {Typography} from "@mui/material";

export default function NoContentPlaceholder({children, sx, ...rest}) {

    return (
        <Typography variant={"body2"} color={"textSecondary"}
                    sx={{my: 2, px: 1, ...sx}}
                    {...rest}>
            {children}
        </Typography>
    )
}
