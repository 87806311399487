/**
 * all string messages will appears here
 */
const MSG = {
    "ELASTIC_EXPORT_FAILED": "Elastic export could not be taken",
    "PASSIVE_BET_CONFIGS_DELETE_FAILED": "Passive bet configs could not be deleted",
    "PASSIVE_BET_CONFIGS_DELETE_DONE": "Passive bet configs’ deleted successfully",
    "ACCOUNT_MOVE_DONE": "Account moved successfully",
    "ACCOUNT_MOVE_ERROR": "Account could not be moved",
    "ACCOUNT_SALE_PEOPLE_DELETE_SUCCESS": "Account sale people deleted successfully",
    "ACCOUNT_SALE_PEOPLE_DELETE_ERROR": "Account sale people could not be deleted",
    "ACCOUNT_SALE_PEOPLE_UPDATE_SUCCESS": "Sale people account updated successfully",
    "ACCOUNT_SALE_PEOPLE_UPDATE_ERROR": "Sale people account could not be updated",
    "ACCOUNT_JUR_DELETE_SUCCESS": "Account jur deleted successfully",
    "ACCOUNT_JUR_DELETE_ERROR": "Account jur could not be deleted",
    "JURISDICTION_DELETE_ERROR": "Jurisdiction could not be deleted",
    "JURISDICTION_DELETE_DONE": "Jurisdiction deleted successfully",
    "LOGIN_ERROR": "Login error",
    "LOGIN_SUCCESS": "Login successful",
    "LOGOUT_ERROR": "Backend logout error",
    "PASS_RESET_ERROR": "Pass reset error",
    "PASS_RESET_SUCCESS": "Pass reset was successful",
    "AUTH_VERIFY_CODE_INVALID_ERROR": "Authentication verify code is invalid",
    "USER_PROFILE_UPDATE_ERROR": "User profile could not be updated",
    "USER_PROFILE_UPDATE_SUCCESS": "User profile updated successfully",
    "USER_PROFILE_UPDATE_WITH_LOGOUT_SUCCESS": "Profile updated successfully, please sign in again to continue.",
    "FORM_VALIDATION_ERROR": "Check the existed errors.",
    "DATA_FETCH_ERROR": "Could not fetch the required data, please check your connection.",
    "GAME_UPDATE_ERROR": "Game could not be updated",
    "GAME_UPDATE_SUCCESS": "Game updated successfully",
    "GAME_ADD_ERROR": "Game could not be added",
    "GAME_ADD_SUCCESS": "Game added successfully",
    "GAME_KEYWORD_UPDATE_ERROR": "Game keyword could not be updated",
    "GAME_KEYWORD_UPDATE_SUCCESS": "Game keyword updated successfully",
    "GAME_JSON_FILE_IMPORT_SUCCESS": "Game json file imported successfully",
    "GAME_JSON_FILE_IMPORT_ERROR": "Game json file could not be imported",
    "GAME_KEYWORD_ADD_EDIT_SUCCESS": "Game keyword adding edited successfully",
    "GAME_KEYWORD_ADD_EDIT_ERROR": "Game keyword adding could not be edited",
    "KEYWORD_ADD_SUCCESS": "Keyword added successfully",
    "KEYWORD_ADD_ERROR": "Keyword could not be added",
    "KEYWORD_UPDATE_ERROR": "Keyword could  not be updated",
    "KEYWORD_UPDATE_SUCCESS": "Keyword updated successfully",
    "GAME_KEYWORD_EXPORT_ERROR": "Game keyword could not be exported",
    "GAME_KEYWORD_EXPORT_SUCCESS": "Game keyword exported successfully",
    "KEYWORD_UPSERT_ERROR": "Keyword could not be upserted",
    "KEYWORD_UPSERT_SUCCESS": "Keyword upserted successfully",
    "KEYWORD_UPSERT_CONFLICT_ERROR": "Keyword already exists.",
    "KEYWORD_DELETE_FROM_GAME_SUCCESS": "Keyword deleted from the game successfully",
    "KEYWORD_DELETE_FROM_GAME_ERROR": "Keyword could not be deleted from the game",
    "KEYWORD_PERMANENTLY_DELETE_SUCCESS": "Keyword permanently deleted successfully",
    "KEYWORD_PERMANENTLY_DELETE_ERROR": "Keyword could not be deleted permanently",
    "KEYWORD_DELETE_FOR_GAME_LANG_SUCCESS": "Keyword deleted from the game language successfully",
    "KEYWORD_DELETE_FOR_GAME_LANG_ERROR": "Keyword could not be deleted from the game language",
    "KEYWORDS_EXPORT_SUCCESS": "Keywords exported successfully",
    "KEYWORDS_EXPORT_ERROR": "Keywords could not be exported",
    "KEYWORDS_TRANSLATIONS_ERROR": "All keywords need to be translated.",
    "LANGUAGE_NOT_SELECTED_ERROR": "Select the target language or add a new one.",
    "LANGUAGE_NOT_SETTED_ERROR": "A name or a key for language should be entered.",
    "KEYWORDS_IMPORT_SUCCESS": "Keywords imported successfully.",
    "KEYWORDS_IMPORT_ERROR": "Keywords could not be imported.",
    "LANGUAGE_UPDATE_ERROR": "Language could not be updated.",
    "LANGUAGE_UPDATE_SUCCESS": "Language updated successfully.",
    "LANGUAGE_ADD_ERROR": "Language could not be added",
    "LANGUAGE_ADD_SUCCESS": "Language added successfully.",
    "KEYWORD_DELETE_SUCCESS": "Keyword deleted successfully.",
    "KEYWORD_DELETE_ERROR": "Keyword could not be deleted.",
    "USER_PROFILE_FETCH_ERROR": "User profile could not be fetched",
    "USER_PROFILE_FETCH_SUCCESS": "User profile fetched successfully.",
    "ADD_USER_SUCCESS": "User added successfully.",
    "ADD_USER_ERROR": "User could not be added.",
    "UPDATE_USER_SUCCESS": "User updated successfully.",
    "UPDATE_USER_ERROR": "User could not be updated.",
    "USER_DELETE_SUCCESS": "User deleted successfully.",
    "USER_DELETE_ERROR": "User could not be deleted",
    "FORCE_PASSWORD_RESET_INFO": "Password needs to be updated.",
    "SYSTEM_PERMISSION_MODIFY_ERROR": "This permission cannot be modified.",
    "USER_PERMISSIONS_FETCH_ERROR": "User permissions could not be fetched",
    "USER_PERMISSIONS_SET_SUCCESS": "User permissions set successfully",
    "USER_PERMISSIONS_SET_ERROR": "User permissions could not be set",
    "USER_RESTRICTION_MODIFY_ERROR": "User restriction could not be modified",
    "USER_RESTRICTION_MODIFY_SUCCESS": "User restriction modified successfully",
    "USER_CUSTOMER_PER_UPDATE_ERROR": "User customer per update error",
    "USER_CUSTOMER_PER_UPDATE_SUCCESS": "User customer per update success",
    "CUSTOMER_DELETE_ERROR": "Customer could not deleted",
    "CUSTOMER_DELETE_SUCCESS": "Customer deleted successfully",
    "UPDATE_CUSTOMER_SUCCESS": "Account updated successfully",
    "UPDATE_CUSTOMER_ERROR": "Account could not be updated",
    "CONTACT_ACCOUNT_ID_REQUIRED_ERROR": "Contact account id required",
    "CONTACT_ADD_ERROR": "Contact could not be added",
    "CONTACT_ADD_SUCCESS": "Contact added successfully",
    "CONTACT_UPDATE_ERROR": "Contact could not be updated",
    "CONTACT_UPDATE_SUCCESS": "Contact updated successfully",
    "ACCOUNT_ID_REQUIRED_ERROR": "Account id required",
    "CUSTOMER_ID_REQUIRED_ERROR": "Customer id required",
    "COMPANY_ADD_ERROR": "Company could not be added",
    "COMPANY_ADD_SUCCESS": "Company added successfully",
    "COMPANY_UPDATE_ERROR": "Company could not be updated",
    "COMPANY_UPDATE_SUCCESS": "Company updated successfully",
    "COMPANY_REMOVE_ERROR": "Company could not be removed",
    "COMPANY_REMOVE_SUCCESS": "Company removed successfully",
    "CONTACT_REMOVE_ERROR": "Contact could not be removed",
    "CONTACT_REMOVE_SUCCESS": "Contact removed successfully",
    "TIMELINE_MESSAGE_ADD_ERROR": "Timeline message could not be added",
    "TIMELINE_MESSAGE_ADD_SUCCESS": "Timeline message added successfully",
    "TIMELINE_MESSAGE_UPDATE_ERROR": "Timeline message could not be updated",
    "TIMELINE_MESSAGE_UPDATE_SUCCESS": "Timeline message updated successfully",
    "ADD_PAYMENT_SUCCESS": "Payment added successfully",
    "ADD_PAYMENT_ERROR": "Payment could not be added",
    "UPDATE_PAYMENT_SUCCESS": "Payment updated successfully",
    "UPDATE_PAYMENT_ERROR": "Payment could not be updated",
    "DEPLOY_GAMES_BUNDLE_FETCH_ERROR": "Deploy games bundle fetch error",
    "DEPLOY_RELEASE_ERROR": "Deploy could not be released",
    "RELEASE_S_PROJECT_REMOVE_SUCCESS": "Release (s) project removed successfully",
    "RELEASE_S_PROJECT_REMOVE_ERROR": "Release (s) project could not be removed",
    "RELEASE_DELETE_SUCCESS": "Release deleted successfully",
    "RELEASE_DELETE_ERROR": "Release could not be deleted",
    "DEPLOY_RELEASES_FETCH_ERROR": "Deploy releases could not be fetched",
    "RELEASE_ADD_ERROR": "Release could not be added",
    "RELEASE_ADD_SUCCESS": "Release added successfully",
    "RELEASE_UPDATE_ERROR": "Release could not be updated",
    "RELEASE_UPDATE_SUCCESS": "Release updated successfully",
    "FETCH_COMMITS_OF_PROJECT_ERROR": "Could not fetch the commits of the project(s)",
    "RELEASE_DEPLOY_ERROR": "Release could not be deployed",
    "RELEASE_DEPLOY_SUCCESS": "Release deployed successfully",
    "RELEASE_PROJECT_APPROVE_ERROR": "Release project could not be approved",
    "RELEASE_PROJECT_APPROVE_SUCCESS": "Release project approved successfully",
    "RELEASE_PROJECT_APPROVE_DELETE_ERROR": "Release project approval could not be deleted",
    "RELEASE_PROJECT_APPROVE_DELETE_SUCCESS": "Release project approval deleted successfully",
    "FONT_DELETE_ERROR": "Font could not be deleted",
    "FONT_DELETE_SUCCESS": "Font deleted successfully",
    "FONT_ADD_ERROR": "Font could not be added",
    "FONT_ADD_SUCCESS": "Font added successfully",
    "FONT_UPDATE_ERROR": "Font could not be updated",
    "FONT_UPDATE_SUCCESS": "Font updated successfully",
    "FONT_DEFAULT_STATUS_CHANGE_ERROR": "Font default status could not be changed",
    "FONT_DEFAULT_STATUS_CHANGE_SUCCESS": "Font default status changed successfully",
    "GAMES_KEYWORDS_BULK_UPDATE_SUCCESS": "Games keywords bulk updated successfully",
    "GAMES_KEYWORDS_BULK_UPDATE_ERROR": "Games keywords bulk could not be updated",
    "ADD_GAME_KEYWORD_TO_RELEASE_SUCCESS": "Game keywords are added to the release successfully",
    "ADD_GAME_KEYWORD_TO_RELEASE_ERROR": "Game keywords could not be added to the release",
    "ADD_GAME_LIBS_TO_RELEASE_SUCCESS": "Game libs are added to the release successfully",
    "ADD_GAME_LIBS_TO_RELEASE_ERROR": "Game libs could not be added to the release",
    "REMOVE_GAME_KEYWORD_TO_RELEASE_SUCCESS": "Game keyword are removed from the release successfully",
    "REMOVE_GAME_KEYWORD_TO_RELEASE_ERROR": "Game keyword could not be removed from the release",
    "RULE_TEMPLATE_LOAD_SUCCESS": "Rule template loaded successfully",
    "RULE_TEMPLATE_LOAD_ERROR": "Rule template could not be loaded",
    "RULE_TEMPLATE_NOT_FOUND": "Rule template could not be found",
    "RULE_UPDATE_ERROR": "Rule could not be updated",
    "RULE_UPDATE_SUCCESS": "Rule updated successfully",
    "SELECT_GAME_AND_LANGUAGE_TO_UPLOAD": "Game and language selection is needed to upload",
    "UPLOADED_FONTS_LOAD_ERROR": "Uploaded fonts could not be loaded",
    "FONT_STYLESHEET_LOAD_ERROR": "Font stylesheet could not be loaded",
    "FONTS_STYLES_UPDATE_ERROR": "Fonts styles could not be updated",
    "FONTS_STYLES_UPDATE_SUCCESS": "Fonts styles updated successfully",
    "RULES_STYLESHEET_LOAD_ERROR": "Rules stylesheet could not be loaded",
    "RULES_STYLES_UPDATE_ERROR": "Rules styles could not be updated",
    "RULES_STYLES_UPDATE_SUCCESS": "Rules styles updated successfully",
    "UPLOADED_GAME_ATTACHMENTS_LOAD_ERROR": "Uploaded game attachments could not be loaded",
    "LIBRARY_DELETE_ERROR": "Library could not be deleted",
    "LIBRARY_DELETE_SUCCESS": "Library deleted successfully",
    "ADD_LIBRARY_TO_GAME_ERROR": "Library could not be added to the game",
    "ADD_LIBRARY_TO_GAME_SUCCESS": "Library is added to the game successfully",
    "REMOVE_LIBRARY_OF_GAME_ERROR": "Library could not be removed from the game",
    "REMOVE_LIBRARY_OF_GAME_SUCCESS": "Library removed from the game successfully",
    "MANUAL_PURGE_ERROR": "Manual purge could not be error",
    "MANUAL_PURGE_SUCCESS": "Manual purge successed",
    "COPY_KEYWORDS_ERROR": "Keywords could not be copied",
    "COPY_KEYWORDS_SUCCESS": "Keywords copied successfully",
    "BULK_GAME_KEYWORDS_UPDATE_ERROR": "Bulk game keywords could not be updated",
    "BULK_GAME_KEYWORDS_UPDATE_SUCCESS": "Bulk game keywords updated successfully",
    "OVERWRITE_KEYWORD_ERROR": "Keyword could not be overwritten",
    "OVERWRITE_KEYWORD_SUCCESS": "Keyword overwritten successfully",
    "RELEASE_ASSETS_PURGE_SUCCESS": "Release assets purge success",
    "RELEASE_ASSETS_PURGE_ERROR": "Release assets purge error",
    "LIB_PRIORITY_UPDATE_SUCCESS": "Library priority updated successfully",
    "LIB_PRIORITY_UPDATE_ERROR": "Lib priority could not be updated",
    "GAME_LANGUAGES_SET_DONE": "Game languages set successfully",
    "GAME_LANGUAGES_SET_ERROR": "Game languages could not be set",
    "GAME_LANGUAGES_FETCH_ERROR": "Game languages could not be fetched",
    "GAME_CONFIGS_FETCH_ERROR": "Game configs could not be fetched",
    "GAME_CONFIGS_FETCH_SUCCESS": "Game configs fetched successfully",
    "GAME_CONFIGS_UPDATE_ERROR": "Game configs could not be updated",
    "GAME_CONFIGS_UPDATE_SUCCESS": "Game configs updated successfully",
    "BACKEND_CONFIGS_FETCH_ERROR": "Backend configs could not be fetched",
    "BACKEND_CONFIGS_UPDATE_ERROR": "Backend configs could not be updated",
    "BACKEND_CONFIGS_UPDATE_SUCCESS": "Bankend configs updated successfully",
    "GAME_RTP_UPDATE_ERROR": "Game rtp could not be updated",
    "GAME_RTP_UPDATE_SUCCESS": "Game rtp updated successfully",
    "GAME_CONFIGS_INVALID": "Game configs are invalid",
    "GAME_PAYLINES_UPDATE_ERROR": "Game paylines could not be updated",
    "GAME_PAYLINES_UPDATE_SUCCESS": "Game paylines updated successfully",
    "GAME_PAYLINES_INVALID": "Game paylines are invalid",
    "GAME_MAX_LIMIT_INVALID": "Game max limit is invalid",
    "GAME_MAX_BET_INVALID": "Game max bet is invalid",
    "GAME_RTP_INVALID": "Game rtp is invalid",
    "NOTIFICATION_REGISTER_FAILED": "Notification register is failed",
    "TOPIC_UNSUBSCRIBE_FAILED": "Topic could not be unsubscribed",
    "TOPIC_UNSUBSCRIBE_DONE": "Topic unsubscribed successfully",
    "TOPIC_SUBSCRIBE_FAILED": "Topic could not be subscribed",
    "TOPIC_SUBSCRIBE_DONE": "Topic subscribed successfully",
    "NOTIFICATION_PERMISSION_ADD_FAILED": "Notification permission could not be added",
    "DELETE_PERMISSION_FAILED": "Permission could not be deleted",
    "DELETE_PERMISSION_DONE": "Permission deleted successfully",
    "UPLOAD_DONE": "Upload is done",
    "ADD_KEYWORDS_TO_RELEASE_SUCCESS": "Keywords are added to the release successfully",
    "ADD_KEYWORDS_TO_RELEASE_ERROR": "Keywords could not be added to the release",
    "UPLOADED_GAME_ASSETS_LOAD_ERROR": "Uploaded game assets could no be loaded",
    "REMOVE_GAME_ASSET_OF_GAME_SUCCESS": "Game asset removed from the game successfully",
    "REMOVE_GAME_ASSET_OF_GAME_ERROR": "Game asset could not be removed from the game",
    "GAME_ASSET_UPLOAD_DONE": "Game asset is uploaded successfully",
    "EMAIL_DELETE_SUCCESS": "Email deleted successfully",
    "EMAIL_DELETE_FAILED": "Email could not be deleted",
    "EMAIL_ADD_FAILED": "Email could not be added",
    "EMAIL_CONTENT_EDIT_FAILED": "Email content could not be edited",
    "EMAIL_ADDRESSES_UPDATE_FAILED": "Email addresses could not be updated",
    "DELETE_ADDRESS_OF_EMAIL_FAILED": "Email address could not be deleted",
    "EMAIL_SEND_FAILED": "Email could not be sent",
    "ASK_TO_SEND_EMAIL_DONE": "Ask to send email done",
    "DELETE_EMAIL_FAILED": "Email could not be deleted",
    "DELETE_EMAIL_SUCCEED": "Email deleted successfully",
    "BET_CONFIG_ADD_ERROR": "Bet config could not be added",
    "BET_CONFIG_ADD_SUCCESS": "Bet config is added successfully",
    "BET_CONFIG_RESTRUCTURE_ERROR": "Bet config could not be restructured",
    "BET_CONFIG_RESTRUCTURE_SUCCESS": "Bet config is restructured successfully",
    "ADD_MATHEMATIC_CONFIG_TO_RELEASE_ERROR": "Mathematics config could not be added to the release",
    "ADD_MATHEMATIC_CONFIG_TO_RELEASE_SUCCESS": "Mathematics config is added to the release successfully",
    "EMAIL_COPY_FAILED": "Email could not be copied",
    "EMAIL_COPY_DONE": "Email copied successfully",
    "ADD_GAME_CONFIGS_TO_RELEASE_ERROR": "Game config could not be added to the release",
    "ADD_GAME_CONFIGS_TO_RELEASE_SUCCESS": "Game config is added to the release successfully",
    "RELEASE_USERS_FETCH_ERROR": "Release users fetch error",
    "NOT_PERMITTED_ACTION_401": "Action is not permitted (401)",
    "GT_USER_AREA_SET_DONE": "Gt user area set successfully",
    "GT_USER_AREA_SET_ERROR": "Gt user area could not be set",
    "GT_USER_PERMISSIONS_SET_DONE": "Gt user permissions set successfully",
    "GT_USER_PERMISSIONS_SET_ERROR": "Gt user permissions could not be set",
    "SITES_OF_GAMES_FETCH_ERROR": "Sites of games could not be fetched",
    "UPDATE_SITE_OF_GAME_FAILED": "Site of game could not be updated",
    "VERSION_HISTORY_FETCH_ERROR": "Version history could not be fetched",
    "BET_CONFIG_UPDATE_ERROR": "Bet config could not be updated",
    "BET_CONFIG_UPDATE_DONE": "Bet config updated successfully",
    "ACCOUNT_TYPE_UPDATE_FAILED": "Account type could not be updated",
    "JURISDICTION_UPDATE_ERROR": "Jurisdiction could not be updated",
    "JURISDICTION_UPDATE_SUCCESS": "Jurisdiction updated successfully",
    "JURISDICTION_ADD_ERROR": "Jurisdiction could not be added",
    "JURISDICTION_ADD_SUCCESS": "Jurisdiction added successfully",
    "CURRENCY_SPECIAL_CONFIG_UPDATE_ERROR": "Currency special config could not be updated",
    "CURRENCY_SPECIAL_CONFIG_UPDATE_SUCCESS": "Currency special config updated successfully",
    "CURRENCY_SPECIAL_CONFIG_ADD_ERROR": "Currency special config could not be added",
    "CURRENCY_SPECIAL_CONFIG_ADD_SUCCESS": "Currency special config is added successfully",
    "ADD_GAME_SQL_TO_RELEASE_ERROR": "Add game sql to release error",
    "ADD_GAME_SQL_TO_RELEASE_SUCCESS": "Add game sql to release success",
    "FIX_GAME_KEYWORDS_ERROR": "Fix game keywords error",
    "FIX_GAME_KEYWORDS_SUCCESS": "Fix game keywords success",
    "TERMINAL_ARCHIVE_REQUEST_ERROR": "Terminal archive request error",
    "TERMINAL_ARCHIVE_REQUEST_DONE": "Terminal archive request done",
    "GAME_UI_VERSION_UPDATE_DONE": "Game ui version update done",
    "GAME_UI_VERSION_UPDATE_ERROR": "Game ui version update error",
    "FETCH_SERVER_INFOS_ERROR": "Fetch server infos error",
    "GAME_SERVER_LANG_PURGE_ERROR": "Game server lang purge error",
    "GAME_SERVER_LANG_PURGE_SUCCESS": "Game server lang purge success",
    "PLAYED_TICKETS_DETAILS_FETCH_ERROR": "Played tickets details fetch error",
    "PLAYED_TICKETS_DETAILS_FETCH_SUCCESS": "Played tickets details fetch success",
    "UPDATE_GAMES_OF_SITES_FAILED": "Update games of sites failed",
    "UPDATE_GAMES_OF_SITES_SUCCESS": "Update games of sites success",
    "GAMES_OF_SITES_FETCH_ERROR": "Games of sites fetch error",
    "CREATE_AREA_SUCCESS": "Create area successfully.",
    "CREATE_AREA_ERROR": "Area could not be created.",
    "COPY_LIBRARY_ERROR": "Libraries could not be copied",
    "COPY_LIBRARY_SUCCESS": "Libraries copied successfully",
    "ADD_CUSTOMER_ERROR": "Customer could not be added",
    "ADD_CUSTOMER_SUCCESS": "Customer added successfully",
}

export default MSG