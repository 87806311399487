import React, {useState, useEffect} from 'react';
import {styled} from '@mui/material/styles';
import cx from 'classnames';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import Collapse from '@mui/material/Collapse';
import {Link} from "react-router-dom";
import {history} from "../../utils";
import {KeyboardArrowUp, KeyboardArrowDown} from '@mui/icons-material';
import {alpha, Box} from "@mui/material";

const ItemDot = styled("span")(({theme}) => ({
    width: 7,
    height: 7,
    borderRadius: 50,
    border: `1px solid ${theme.palette.primary.main}`,
    marginLeft: -8,
    marginRight: 16,
    "&.selected": {
        backgroundColor: theme.palette.primary.main
    }
}))

const PREFIX = 'NestedNavigation';

const classes = {
    header: `${PREFIX}-header`,
    headerSelected: `${PREFIX}-headerSelected`,
    headerExpanded: `${PREFIX}-headerExpanded`,
    item: `${PREFIX}-item`,
    itemArrow: `${PREFIX}-itemArrow`,
    toggle: `${PREFIX}-toggle`,
    sub1: `${PREFIX}-sub1`,
    sub1Selected: `${PREFIX}-sub1Selected`,
    sub1Expanded: `${PREFIX}-sub1Expanded`,
    sub2: `${PREFIX}-sub2`,
    sub2Selected: `${PREFIX}-sub2Selected`,
    itemIcon: `${PREFIX}-itemIcon`,
    itemIconL1: `${PREFIX}-itemIconL1`,
    collapse: `${PREFIX}-collapse`,
    itemLabel: `${PREFIX}-itemLabel`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
    {
        theme
    }
) => {
    const {palette, spacing, shape} = theme;

    const primary = palette.primary[500] || palette.primary.main;
    const paper = palette.background.paper;
    const primaryColorContrast = palette.getContrastText(primary);

    return {
        marginBottom: 4,
        // NavItem
        [`& .${classes.header}`]: {
            display: 'flex',
            width: ' auto',
            position: ' relative',
            transition: ' all 300ms linear',
            fontWeight: ' 300',
            lineHeight: ' 1.5em',
            marginBottom: 4,
        },
        [`& .${classes.headerSelected}`]: {
            '& > $item': {
                transition: 'all 300ms linear'
            }
        },
        [`& .${classes.headerExpanded}`]: {
            // backgroundColor: alpha(paper, .9)
        },
        [`& .${classes.item}`]: {
            minWidth: 0,
            flexGrow: 1,
            padding: `${spacing(.75)} ${spacing(1)}`,
            borderRadius: shape.borderRadius,
        },
        [`& .${classes.itemArrow}`]: {
            margin: '0 -4px 0 auto',
            fontSize: "1rem"
        },
        [`& .${classes.toggle}`]: {
            minWidth: 48,
        },
        // -------------------------
        // Sub 1
        [`& .${classes.sub1}`]: {
            width: 'auto',
            display: 'flex',
            padding: 0,
            position: 'relative',
            transition: 'all 300ms linear',
            fontWeight: '300',
            minHeight: '36px',
            // backgroundColor: alpha(primary, .06),

            '&>a': {
                width: "100%",
                padding: `${spacing(.75)} ${spacing(1)}`,
                paddingLeft: 30
            }
        },
        [`& .${classes.sub1Selected}`]: {
            color: primaryColorContrast,
            // backgroundColor: alpha(primary, .8)
        },
        [`& .${classes.sub1Expanded}`]: {},
        // -------------------------
        // Sub 2
        [`& .${classes.sub2}`]: {
            paddingLeft: 64,
            position: 'relative',
            '&:before': {
                content: '" "',
                position: 'absolute',
                width: 3,
                height: '100%',
                left: 40
            }
        },
        [`& .${classes.sub2Selected}`]: {
            '&:after': {
                content: '" "',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                width: 3,
                height: '40%',
                left: 40,
            },
        },

        [`& .${classes.itemIcon}`]: {
            display: "inline-block",
            fontSize: "1.25rem",
            transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            flexShrink: 0,
            userSelect: "none",
            marginRight: theme.spacing() / 2,
            "& [stroke-width]": {
                stroke: "currentColor",
            }
        },
        [`& .${classes.itemIconL1}`]: {
            width: "1em",
            height: "1em",
            display: "inline-block",
            fontSize: "1.1rem",
            transition: "fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            flexShrink: 0,
            userSelect: "none",
            marginRight: theme.spacing(1),
        },
        [`& .${classes.collapse}`]: {
            // backgroundColor: alpha(paper, .8)
        },
        [`& .${classes.itemLabel}`]: {
            textOverflow: "ellipsis",
            display: "inline-block",
            overflow: "hidden",
            fontSize: 14
        }
    };
});

const NavItem = ({
                     label,
                     selected,
                     expanded,
                     separated,
                     toggle = separated,
                     onMenuClick,
                     onToggle,
                     path,
                     icon: MuiIcon,
                     ...menuItemProps
                 }) => {

    const ArrowIcon = expanded ? KeyboardArrowUp : KeyboardArrowDown;

    return (
        <div
            className={cx(
                classes.header,
                selected && classes.headerSelected,
                expanded && classes.headerExpanded,
            )}
        >
            {separated ? (
                <>
                    <MenuItem
                        className={classes.item}
                        {...menuItemProps}
                        onClick={onMenuClick}
                    >
                        {MuiIcon && <MuiIcon className={classes.itemIcon}/>}
                        <span className={classes.itemLabel}>{label}</span>
                    </MenuItem>
                    {toggle && (
                        <ButtonBase className={classes.toggle} onClick={onToggle}>
                            <ArrowIcon/>
                        </ButtonBase>
                    )}
                </>
            ) : (
                <MenuItem
                    className={classes.item}
                    {...menuItemProps}
                    onClick={e => {
                        onToggle(e);
                        onMenuClick(e);
                    }}
                >
                    {MuiIcon && <MuiIcon className={classes.itemIconL1}/>}
                    <span className={classes.itemLabel}>{label}</span>
                    {toggle && <ArrowIcon className={classes.itemArrow}/>}
                </MenuItem>
            )}
        </div>
    );
};

NavItem.defaultProps = {
    onToggle: () => {
    },
    onMenuClick: () => {
    },
};

const NestedNavigation = ({menus, selectedKey, openKeys}) => {

    const [currentKey, setCurrentKey] = useState(selectedKey || '');
    const [currentOpenKeys, setCurrentOpenKeys] = useState(openKeys || []);

    useEffect(() => {
        setCurrentKey(selectedKey);
    }, [selectedKey]);

    useEffect(() => {
        setCurrentOpenKeys(openKeys);
    }, [openKeys]);

    const handleToggle = key => () => {
        if (currentOpenKeys.includes(key)) {
            return setCurrentOpenKeys(currentOpenKeys.filter(k => k !== key));
        }
        return setCurrentOpenKeys([...currentOpenKeys, key]);
    };

    function renderMenus(level) {
        return (menuItem) => {

            const {
                key, label, subMenus, separated,
                icon: MuiIcon, path, ...rest
            } = menuItem


            return (
                <Root key={key}>
                    {level === 0 ? (
                        <NavItem
                            label={label}
                            selected={
                                subMenus ? separated && currentKey === key : currentKey === key
                            }
                            icon={MuiIcon}
                            expanded={currentOpenKeys.includes(key)}
                            separated={separated}
                            onMenuClick={e => {
                                if (!subMenus || separated) {
                                    if (path)
                                        history.push(path, e);
                                    setCurrentKey(key);
                                }
                                if (subMenus && !currentOpenKeys.includes(key)) {
                                    handleToggle(key)();
                                }
                            }}
                            path={path}
                            {...subMenus && {
                                toggle: true,
                                onToggle: handleToggle(key),
                            }}
                            {...rest}
                        />
                    ) : (
                        <div className={cx(
                            classes.item,
                            classes[`sub${level}`],
                            currentOpenKeys.includes(key) && classes[`sub${level}Expanded`],
                        )}>
                            <MenuItem
                                className={classes.item}
                                component={Link}
                                to={path}
                                onClick={e => {
                                    if (subMenus)
                                        handleToggle(key)()
                                    else {
                                        if (path) {
                                            history.push(path, e);
                                        }
                                        setCurrentKey(key)
                                    }
                                }}
                                {...rest}
                            >
                                <ItemDot className={cx(currentKey === key && "selected")}/>
                                <span className={classes.itemLabel}>{label}</span>
                            </MenuItem>
                        </div>
                    )}
                    {subMenus && (
                        <Collapse className={classes.collapse} in={currentOpenKeys.includes(key)}>
                            {subMenus.map(renderMenus(level + 1))}
                        </Collapse>
                    )}
                </Root>
            );
        };
    }

    return menus.map(renderMenus(0));
};

NestedNavigation.propTypes = {
    menus: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string,
            label: PropTypes.string,
        }),
    ),
    selectedKey: PropTypes.string,
    openKeys: PropTypes.arrayOf(PropTypes.string),
};
NestedNavigation.defaultProps = {
    menus: [],
    selectedKey: '',
    openKeys: [],
};

export default NestedNavigation;
