import React from 'react';
import {ThemeProvider, StyledEngineProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {useSelector} from "react-redux";
import {dangerTheme} from "./utils/mui-theme";

export default function Theming({children}) {
    const theme = useSelector(state => state.settings.theme)
    // console.log(theme);
    return (
        <StyledEngineProvider injectFirst>
            <CssBaseline/>
            <ThemeProvider theme={theme}>
                {children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export function DangerTheming({children}) {

    return (
        <ThemeProvider theme={dangerTheme}>
            {children}
        </ThemeProvider>
    );
}

