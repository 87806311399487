import React, {useEffect} from "react";
import {settingsActions} from "../actions/settings.actions";
import {useDispatch} from "react-redux";

/**
 * change the title of current route
 */
function usePageTitleChange(watch, title) {

    const dispatch = useDispatch();

    // update page title
    useEffect(() => {
        if (title !== void 0)
            dispatch(settingsActions.setCurrentRouteObj({title}))

    }, [watch])
}

export default usePageTitleChange
