/**
 * Redux actions for auth stuffs.
 */
import {authService, setOneTimeToken} from "../services/auth.service";
import {history} from "../utils";
import * as axios from "axios";
import MSG from "../constants/messages.constants";
import {getGlobalData} from "../hooks/data-hooks/data.hook";
import jwtParser from "../utils/jwt-parse";
import {HOOKS} from "js-service-wrapper";
import {authConstants} from "../constants";
import config from "../config.json";

export const authActions = {
    loginAttempt,
    logout,
    setLoggedIn,
    updateOneTimeToken,
    updateAdminState,
};

/**
 * attempt to server for auth and dispatch action on success
 * @param credentials
 * @param callback
 * @returns {Function}
 */
function loginAttempt(credentials, callback) {
    return dispatch => {

        authService.login(credentials)
            .setHook(HOOKS.BEFORE_RESOLVE, res => res)
            .fire({rejectMSG: MSG.LOGIN_ERROR, resolveMSG: MSG.LOGIN_SUCCESS})
            .then(function (res) {

                // parse jwt to get userId
                const parsedJWT = jwtParser(res.data.JwtToken);

                let mustSave = {
                    admin: {
                        "Id": parsedJWT.userid,
                        "FirstName": res.data.FirstName,
                        "LastName": res.data.LastName,
                        "Email": res.data.Email,
                        "Username": res.data.Username,
                        "PhoneNumber": res.data.PhoneNumber,
                        "UserCreatedDate": res.data.UserCreatedDate,
                        "CurrencyId": res.data.CurrencyId,
                        "Timezone": res.data.Timezone,
                        "ForceToChangePassword": res.data.ForceToChangePassword,
                        "ProdPermit": res.data.ProdPermit,
                        "UserGroup": res.data.UserGroup,
                        "Role": res.data.Role,
                    },
                    roles: res.data.PermittedCalls,
                    token: res.data.JwtToken,
                    oneTimeToken: res.headers["x-next-token"]
                };

                authService.setLocalCryptoItem(config.AUTH_USER_ROLES_KEY, mustSave.roles)
                authService.setLocalCryptoItem(config.AUTH_USER_KEY, mustSave.admin)
                authService.setLocalCryptoItem(config.AUTH_TOKEN_KEY, mustSave.token)
                authService.setLocalCryptoItem(config.AUTH_ONE_TIME_TOKEN_KEY, mustSave.oneTimeToken)

                dispatch({
                    type: authConstants.LOGIN_SUCCESS,
                    payload: {
                        admin: mustSave.admin,
                        oneTimeToken: mustSave.oneTimeToken,
                        roles: mustSave.roles,
                    }
                });

                axios.defaults.headers.common['token'] = authService.getToken();
                axios.defaults.headers.common['Content-Type'] = 'application/json';

                if (!authService.forcePasswordChange())
                    getGlobalData()

                history.push(window.LAST_VISITED_URL || '/');
                delete window.LAST_VISITED_URL;

            })
            .catch(err => {
                if (callback && typeof callback === "function")
                    callback()
            })
    }
}

/**
 * dispatch logout action
 * @returns {Function}
 */
function logout() {
    return dispatch => {
        authService.logout();

        dispatch({
            type: authConstants.LOGOUT,
        });
    }
}

/**
 * action to set the status of auth on each reload.
 *
 * @returns {Function}
 */
function setLoggedIn() {

    return dispatch => {
        const isAuthenticated = authService.isAuthenticated()
        const admin = authService.getUser();
        const roles = authService.getUserRoles();

        dispatch({
            type: authConstants.SET_LOGGED_IN_STATUS,
            payload: {
                isAuthenticated: isAuthenticated,
                admin: admin,
                roles: roles
            }
        })
    }
}

/**
 * action to set the status of auth on each reload.
 *
 * @returns {Function}
 */
function updateAdminState(admin = null) {
    return dispatch => {
        authService.setLocalCryptoItem(config.AUTH_USER_KEY, admin)

        dispatch({
            type: authConstants.UPDATE_ADMIN_STATE,
            payload: admin
        })
    }
}

/**
 * action to set new value of OneTimeToken on each request.
 *
 * @returns {Function}
 */
function updateOneTimeToken(oneTimeToken = null) {
    setOneTimeToken(oneTimeToken)
    return dispatch => {
        dispatch({
            type: authConstants.UPDATE_ONE_TIME_TOKEN,
            payload: oneTimeToken
        })
    }
}
