import React from 'react';
import ReactDOM from 'react-dom';
import {Router} from "react-router-dom";
import {Provider} from "react-redux";
import NotifyService from "./utils/notification-service";
import {reduxStore, history} from "./utils";
import App from './app';
import "./utils/version-logger";
import "./styles/index.scss"

ReactDOM.render(
    <Provider store={reduxStore}>
        <Router history={history}>
            <App/>
        </Router>
    </Provider>, document.getElementById('root'));

// Push notification service registration
NotifyService.start();
