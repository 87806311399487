export default function SingletonFactory(Constructor, ...args) {
    let instance;

    const generateInstance = () => {
        instance = new Constructor(...args)
    }

    return {
        getInstance() {
            if (!instance)
                generateInstance();

            return instance
        }
    }

}