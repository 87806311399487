import SimpleCrypto from "simple-crypto-js";
import MSG from "../constants/messages.constants";
import {isValidJSON, reduxStore} from "../utils";
import {ClientHandler} from "js-service-wrapper";
import config from "../config.json"
import BASE_SERVICE_URL from "./get-service-url";

// init crypto and set a key for it
let simpleCrypto = new SimpleCrypto('jsdWEnmDFdl4sd@#02er@#$342Dsd4fmk34sdf5jhs5d5sdfsdfbx5zsdSDSsdfsdSdf5d7fcsDFSF');
window.ddd = simpleCrypto

function login({username, email, password, verification = null}) {
    return new ClientHandler({
        method: 'post',
        url: BASE_SERVICE_URL + '/login',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            username,
            email,
            password,
            verification
        }
    });
}

function passwordReset(data) {
    return new ClientHandler({
        method: 'post',
        url: BASE_SERVICE_URL + '/forgotten',
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

function isAuthenticated() {
    let user = getLocalCryptoItem(config.AUTH_USER_KEY);
    let token = getLocalCryptoItem(config.AUTH_TOKEN_KEY);

    return !!(user && token);
}

function isPermittedAction(action) {
    let roles = getUserRoles();

    if (!roles || !action) return false;

    return !!roles[action]
}

/**
 * get token from localstorage
 * @returns {null|(null|undefined)}
 */
export function getToken() {
    let token = getLocalCryptoItem(config.AUTH_TOKEN_KEY)

    if (!token)
        return null;

    return token
}

/**
 * get one-time-token from localstorage
 * @returns {null|(null|undefined)}
 */
export function getOneTimeToken() {
    return getLocalCryptoItem(config.AUTH_ONE_TIME_TOKEN_KEY)
}

export function setOneTimeToken(value) {
    return setLocalCryptoItem(config.AUTH_ONE_TIME_TOKEN_KEY, value)
}

/**
 * get user roles from localstorage
 * @returns {null|(null|undefined)}
 */
export function getUserRoles() {
    let userRoles = getLocalCryptoItem(config.AUTH_USER_ROLES_KEY)

    if (!userRoles)
        return null;

    if (isValidJSON(userRoles))
        userRoles = JSON.parse(userRoles)

    return userRoles
}

/**
 * get user from localstorage
 * @returns {string|any}
 */
export function getUser() {
    let user = getLocalCryptoItem(config.AUTH_USER_KEY)
    let token = getLocalCryptoItem(config.AUTH_TOKEN_KEY)

    if (user && token)
        return user;

    return '';
}

/**
 * method to handle user logout
 * @returns {never}
 */
function logout() {

    // remove dat from localStorage
    localStorage.removeItem(config.AUTH_USER_KEY);
    localStorage.removeItem(config.AUTH_TOKEN_KEY);
    localStorage.removeItem(config.AUTH_REFRESH_TOKEN_KEY);
    localStorage.removeItem(config.AUTH_ONE_TIME_TOKEN_KEY);
    localStorage.removeItem(config.AUTH_USER_ROLES_KEY);

    // request to expire current token on server
    new ClientHandler({
        method: 'post',
        url: BASE_SERVICE_URL + '/logout',
        headers: {
            'Content-Type': 'application/json'
        },
    }).fire({rejectMSG: MSG.LOGOUT_ERROR})
}

/**
 *
 * @returns {ClientHandler}
 */
function getUserProfile() {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + '/user/me',
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

/**
 *
 * @param data
 * @returns {ClientHandler}
 */
function updateProfile(data) {
    return new ClientHandler({
        method: 'put',
        url: BASE_SERVICE_URL + '/user/me',
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 * set an item to localStorage with cryoto
 * @param key
 * @param value
 * @returns {boolean}
 */
function setLocalCryptoItem(key, value) {
    try {
        localStorage.setItem(key, value ? simpleCrypto.encrypt(value) : "")

        return true
    } catch (e) {
        return false;
    }
}

/**
 * get a crypted value from localStorage
 * @param key
 * @returns {null}
 */
function getLocalCryptoItem(key) {
    try {
        let data = localStorage.getItem(key)

        return !!data ? simpleCrypto.decrypt(data) : null;
    } catch (e) {
        return null;
    }

}

function askVerifyCodeToLogin(credentials = {}) {
    return new Promise((resolve, reject) => {
        authService.login(credentials)
            .fire({rejectMSG: MSG.LOGIN_ERROR})
            .then(function (res) {
                resolve(true)
            })
            .catch(err => {
                reject(err);
            })
    })
}

function attemptToPassReset(data) {
    return new Promise((resolve, reject) => {
        authService.passwordReset(data)
            .fire({rejectMSG: MSG.LOGIN_ERROR})
            .then(function (res) {
                resolve(true)
            })
            .catch(err => {
                console.log(err);
                reject();
            })
    })
}

function askVerificationCodeToPassReset(data) {
    return attemptToPassReset(data)
}

function forcePasswordChange() {
    const admin = reduxStore.getState().auth.admin;
    return !!(admin && admin.ForceToChangePassword)
}

// export all methods under a name "authServices"
export const authService = {
    login,
    passwordReset,
    askVerifyCodeToLogin,
    askVerificationCodeToPassReset,
    logout,
    isAuthenticated,
    isPermittedAction,
    getUser,
    getUserProfile,
    updateProfile,
    getToken,
    getUserRoles,
    setLocalCryptoItem,
    forcePasswordChange,
};
