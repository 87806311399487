import config from "../config.json";

function getServiceURL() {
    if (!!window.location.port) {
        return config.LOCAL_SERVICE_URL || config.SERVICE_URL
    }

    return config.SERVICE_URL
}

const BASE_SERVICE_URL = getServiceURL()

export default BASE_SERVICE_URL