import {newRoutes, toPath} from "./routes";

export const generalSearchItem = [

    // Behnam
    generateGeneralSearchItem({
        title: newRoutes.notifications.title,
        path: newRoutes.notifications.path,
        childItems: [
            generateGeneralSearchItem({
                title: newRoutes.notificationsPermissions.title,
                path: newRoutes.notificationsPermissions.path,
                parent: newRoutes.notifications.path,
            }),
            generateGeneralSearchItem({
                title: newRoutes.notificationsSubscription.title,
                path: newRoutes.notificationsSubscription.path,
                parent: newRoutes.notifications.path,
            }),
        ]
    }),
    generateGeneralSearchItem({
        title: newRoutes.keywordsList.title,
        path: newRoutes.keywordsList.path,
        childItems: [
            generateGeneralSearchItem({
                title: newRoutes.keywordAdd.title,
                path: newRoutes.keywordAdd.path,
                parent: newRoutes.keywordsList.title,
            }),
            generateGeneralSearchItem({
                title: newRoutes.keywordsImport.title,
                description: "Import Translations",
                path: newRoutes.keywordsImport.path,
                parent: newRoutes.keywordsList.title,
            }),
            generateGeneralSearchItem({
                title: newRoutes.gameKeywordBulkUpdate.title,
                path: newRoutes.gameKeywordBulkUpdate.path,
                parent: newRoutes.keywordsList.title,
            }),
            generateGeneralSearchItem({
                title: "Fix Game Keywords",
                path: newRoutes.keywordsList.path + "?modal=fix",
                parent: newRoutes.keywordsList.title,
            }),
            generateGeneralSearchItem({
                title: "Bulk Game Keywords Update",
                path: newRoutes.keywordsList.path + "?modal=bulk-update",
                parent: newRoutes.keywordsList.title,
            }),
            generateGeneralSearchItem({
                title: "Keyword Overwrite",
                path: newRoutes.keywordsList.path + "?modal=overwrite",
                parent: newRoutes.keywordsList.title,
            }),
            generateGeneralSearchItem({
                title: "Keyword Copy",
                path: newRoutes.keywordsList.path + "?modal=copy",
                parent: newRoutes.keywordsList.title,
            }),
            generateGeneralSearchItem({
                title: "Export Keywords",
                path: newRoutes.keywordsList.path + "?modal=export",
                parent: newRoutes.keywordsList.title,
            }),
        ]
    }),
    generateGeneralSearchItem({
        title: newRoutes.gamesList.title,
        path: newRoutes.gamesList.path,
        childItems: [
            generateGeneralSearchItem({
                title: "Add New Game",
                path: newRoutes.gameAdd.path,
                parent: newRoutes.keywordsList.title,
            }),
            generateGeneralSearchItem({
                title: "Update Prod UI Version",
                path: newRoutes.gamesList.path + "?modal=prod-ui-update",
                parent: newRoutes.gamesList.title,
            }),
            generateGeneralSearchItem({
                title: "Purge Game Server Language",
                path: newRoutes.gamesList.path + "?modal=purge-langs",
                parent: newRoutes.gamesList.title,
            }),
        ]
    }),
    generateGeneralSearchItem({
        title: newRoutes.fonts.title,
        path: newRoutes.fonts.path,
        childItems: [
            generateGeneralSearchItem({
                title: "Add New Font",
                path: newRoutes.keywordsList.path + "?modal=new",
                parent: newRoutes.keywordsList.title,
            }),
            generateGeneralSearchItem({
                title: newRoutes.fontStyles.title,
                path: newRoutes.fontStyles.path,
                parent: newRoutes.fonts.title,
            }),
        ]
    }),
    generateGeneralSearchItem({
        title: newRoutes.libraries.title,
        path: newRoutes.libraries.path,
        childItems: [
            generateGeneralSearchItem({
                title: newRoutes.librariesAdd.title,
                path: newRoutes.librariesAdd.path,
                parent: newRoutes.libraries.title
            }),
            generateGeneralSearchItem({
                title: "Library Batch Update",
                path: newRoutes.libraries.path + "?modal=batch-update",
                parent: newRoutes.libraries.title,
            }),
            generateGeneralSearchItem({
                title: "Copy Game Libraries",
                path: newRoutes.libraries.path + "?modal=library-copy",
                parent: newRoutes.libraries.title,
            }),
        ],
    }),
    generateGeneralSearchItem({
        title: "Add New User",
        path: newRoutes.userAdd.path,
    }),

    // Busra

    generateGeneralSearchItem({
        title: newRoutes.customers.title,
        path: newRoutes.customers.path,
        childItems: [
            generateGeneralSearchItem({
                title: newRoutes.addCustomer.title,
                path: newRoutes.addCustomer.path,
                parent: newRoutes.customers.title,
            }),
        ]
    }),
    generateGeneralSearchItem({
        title: newRoutes.customerHistory.title,
        path: newRoutes.customerHistory.path,
    }),
    generateGeneralSearchItem({
        title: newRoutes.jurisdictions.title,
        path: newRoutes.jurisdictions.path,
        childItems: [
            generateGeneralSearchItem({
                title: "Add Jurisdiction",
                path: newRoutes.jurisdictions.path + "?modal=new",
                parent: newRoutes.jurisdictions.title,
            }),
        ]
    }),
    generateGeneralSearchItem({
        title: newRoutes.elasticExport.title,
        path: newRoutes.elasticExport.path,
    }),
    generateGeneralSearchItem({
        title: newRoutes.freeTickets.title,
        path: newRoutes.freeTickets.path,
    }),
    generateGeneralSearchItem({
        title: newRoutes.playedFreeTickets.title,
        path: newRoutes.playedFreeTickets.path,
    }),
    generateGeneralSearchItem({
        title: newRoutes.releasesList.title,
        path: newRoutes.releasesList.path,
        childItems: [
            generateGeneralSearchItem({
                title: "New Release",
                path: newRoutes.releasesList.path + "?modal=new",
                parent: newRoutes.releasesList.title,
            }),
        ]
    }),
    generateGeneralSearchItem({
        title: newRoutes.gamesOfSites.title,
        path: newRoutes.gamesOfSites.path,
    }),
    generateGeneralSearchItem({
        title: newRoutes.versions.title,
        path: newRoutes.versions.path,
    }),
    generateGeneralSearchItem({
        title: newRoutes.rulesEditor.title,
        path: newRoutes.rulesEditor.path,
        childItems: [
            generateGeneralSearchItem({
                title: newRoutes.rulesOfGames.title,
                path: newRoutes.rulesOfGames.path,
                parent: newRoutes.rulesEditor.title,
            }),

        ]
    }),
    generateGeneralSearchItem({
        title: newRoutes.rulesSharedStyles.title,
        path: newRoutes.rulesSharedStyles.path,
    }),

    // Betul
    generateGeneralSearchItem({
        title: newRoutes.emailingContacts.title,
        path: newRoutes.emailingContacts.path,
        childItems: [
            generateGeneralSearchItem({
                title: "Add Contact",
                path: newRoutes.emailingContacts.path + "?modal=new",
                parent: newRoutes.emailingContacts.title
            }),
        ]
    }),
    generateGeneralSearchItem({
        title: newRoutes.emailingMails.title,
        path: newRoutes.emailingMails.path,
        childItems: [
            generateGeneralSearchItem({
                title: "Add Email",
                path: newRoutes.emailingNewEmail.path,
                parent: newRoutes.emailingMails.title
            }),
        ]
    }),

    generateGeneralSearchItem({
        title: newRoutes.betConfigsSites.title,
        path: newRoutes.betConfigsSites.path,
    }),
    generateGeneralSearchItem({
        title: newRoutes.betConfigsAccounts.title,
        path: newRoutes.betConfigsAccounts.path,
    }),
    generateGeneralSearchItem({
        title: newRoutes.betConfigsNew.title,
        path: newRoutes.betConfigsNew.path,
    }),
    generateGeneralSearchItem({
        title: newRoutes.betConfigsList.title,
        path: newRoutes.betConfigsList.path,
        keywords: ["bahis", "bets"],
        childItems: [
            generateGeneralSearchItem({
                title: "Currency Special Config Update",
                path: newRoutes.betConfigsList.path + "?modal=currency-special-config-update",
                parent: newRoutes.betConfigsList.title
            }),
            generateGeneralSearchItem({
                title: "Currency Special Config For Game",
                path: newRoutes.betConfigsList.path + "?modal=currency-special-config-for-game",
                parent: newRoutes.betConfigsList.title
            }),
            generateGeneralSearchItem({
                title: "Delete Passive Bet Config",
                path: newRoutes.betConfigsList.path + "?modal=delete-passive-bet-config",
                parent: newRoutes.betConfigsList.title
            }),
        ]
    }),
    generateGeneralSearchItem({
        title: newRoutes.betConfigsLimits.title,
        path: newRoutes.betConfigsLimits.path,
    }),
    generateGeneralSearchItem({
        title: newRoutes.gameTechUsers.title,
        path: newRoutes.gameTechUsers.path,
    }),
    generateGeneralSearchItem({
        title: newRoutes.gameTechLocations.title,
        path: newRoutes.gameTechLocations.path,
    }),
    generateGeneralSearchItem({
        title: newRoutes.gameTechNewArea.title,
        path: newRoutes.gameTechNewArea.path,
    }),
]

function generateGeneralSearchItem(
    {
        title = "",
        path = "",
        description = "",
        keywords = [],
        parent = "",
        childItems = []
    }) {

    let obj = {title, path}
    if (description)
        obj.description = description
    if (keywords)
        obj.keywords = keywords
    if (parent)
        obj.parent = parent
    if (childItems)
        obj.childItems = childItems

    return obj
}

export function generateGameSearchItem(game) {

    const childItems = [
        generateGeneralSearchItem({
            title: 'Edit Game',
            description: `${game.Name} Edit`,
            keywords: [game.Name, game.FriendlyName],
            parent: game.Name,
            path: toPath(newRoutes.gameEdit.path, game.Id)
        }),
        generateGeneralSearchItem({
            title: 'Keywords',
            description: `${game.Name} Keywords`,
            keywords: [game.Name, game.FriendlyName],
            parent: game.Name,
            path: toPath(newRoutes.gameKeywords.path, game.Id)
        }),
        generateGeneralSearchItem({
            title: 'Attachments',
            description: `${game.Name} Attachments`,
            keywords: [game.Name, game.FriendlyName],
            parent: game.Name,
            path: toPath(newRoutes.gameAttachments.path, game.Id)
        }),
        generateGeneralSearchItem({
            title: 'Libraries',
            description: `${game.Name} Libraries`,
            keywords: [game.Name, game.FriendlyName],
            parent: game.Name,
            path: toPath(newRoutes.gameLibraries.path, game.Id)
        }),
        generateGeneralSearchItem({
            title: 'Assets',
            description: `${game.Name} Assets`,
            keywords: [game.Name, game.FriendlyName],
            parent: game.Name,
            path: toPath(newRoutes.gameAssets.path, game.Id)
        }),
        generateGeneralSearchItem({
            title: 'Configs',
            description: `${game.Name} Configs`,
            keywords: [game.Name, game.FriendlyName],
            parent: game.Name,
            path: toPath(newRoutes.game.path, game.Id) + "?modal=configs"
        }),
        generateGeneralSearchItem({
            title: 'Languages',
            description: `${game.Name} Languages`,
            keywords: [game.Name, game.FriendlyName],
            parent: game.Name,
            path: toPath(newRoutes.game.path, game.Id) + "?modal=languages"
        }),
    ]

    return generateGeneralSearchItem({
        path: toPath(newRoutes.game.path, game.Id),
        title: game.Name,
        description: game.FriendlyName,
        childItems
    })
}



