/**
 * a simple utils to handle keyword types of the Back Office
 */
export const keyTypesOptions = [
    {key: 0, text: "All", value: null},
    {key: 1, text: "Game", value: 0},
    {key: 2, text: "MobileUI", value: 1},
    {key: 3, text: "Paytable", value: 2},
];

/**
 * this will return a string value for a typeNum
 * @param typeNum
 * @returns {*}
 */
export function findTargetKeywordType(typeNum) {
    if (![0, 1, 2].includes(typeNum)) return typeNum

    return keyTypesOptions.find(kt => kt.value === typeNum).text
}