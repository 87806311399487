import {ClientHandler} from "js-service-wrapper";
import BASE_SERVICE_URL from "./get-service-url";

/**
 *
 * @returns {ClientHandler}
 * @param Endpoint
 * @param Auth
 * @param P256dh
 * @param client
 */
function pushNotificationRegister(Endpoint, Auth, P256dh, client) {
    return new ClientHandler({
        method: 'post',
        url: BASE_SERVICE_URL + '/notification/register',
        headers: {
            'Content-Type': 'application/json',
        },
        data: {
            Endpoint,
            Auth,
            P256dh,
            client
        }
    });
}

/**
 *
 * @param params
 * @returns {ClientHandler}
 */
function getAllNotifications(params) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + '/notification',
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 *
 * @param data
 * @returns {ClientHandler}
 */
function topicSubscriptionUpdate(data) {
    return new ClientHandler({
        method: 'post',
        url: BASE_SERVICE_URL + `/notification/subscribe/topic`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 *
 * @param data
 * @returns {ClientHandler}
 */
function customSubscriptionUpdate(data) {
    return new ClientHandler({
        method: 'post',
        url: BASE_SERVICE_URL + `/notification/subscribe`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 *
 * @param data
 * @returns {ClientHandler}
 */
function markAsRead(data = null) {
    return new ClientHandler({
        method: 'post',
        url: BASE_SERVICE_URL + `/notification/read`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 *
 * @param params
 * @returns {ClientHandler}
 */
function getSubscriptions(params = null) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + `/notification/subscription`,
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 *
 * @param params
 * @returns {ClientHandler}
 */
function getPermissions(params = null) {
    return new ClientHandler({
        method: 'get',
        url: BASE_SERVICE_URL + `/notification/permission`,
        headers: {
            'Content-Type': 'application/json',
        },
        params
    });
}

/**
 *
 * @returns {ClientHandler}
 * @param data
 */
function addPermission(data = null) {
    return new ClientHandler({
        method: 'post',
        url: BASE_SERVICE_URL + `/notification/permission/add`,
        headers: {
            'Content-Type': 'application/json',
        },
        data
    });
}

/**
 *
 * @returns {ClientHandler}
 * @param id
 */
function deletePermission(id) {
    return new ClientHandler({
        method: 'delete',
        url: BASE_SERVICE_URL + `/notification/permission/${id}`,
        headers: {
            'Content-Type': 'application/json',
        },
    });
}

export const notificationsService = {
    pushNotificationRegister,
    getAllNotifications,
    topicSubscriptionUpdate,
    customSubscriptionUpdate,
    markAsRead,
    getSubscriptions,
    getPermissions,
    addPermission,
    deletePermission,
};
